import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logoutUser} from '../../actions/auth';
import {clearSearch} from '../../actions/esearch';

import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
    ChevronLeft,
    ChevronRight,
    Search,
    YoutubeSearchedFor,
    Group,
    Business,
    CenterFocusStrongOutlined,
    Gavel,
    AccountCircle,
    ShoppingCart,
    Assignment,
    Shop,
    AssignmentOutlined,
    Create
} from '@material-ui/icons';
import {
    Divider,
    Drawer,
    List,
    IconButton,
    Typography,
    Toolbar,
    AppBar,
    Menu,
    MenuItem,
    Badge,
} from '@material-ui/core';

import clsx from 'clsx';
import RegisteredRoutes from './RegisteredRoutes';
import ListItemLink from './ListItemLink';
import SelectLanguage from '../accounts/SelectLanguage';
import {withTranslation} from 'react-i18next';

const drawerWidth = 240;

const useStyles = theme => {
    return ({
        root: {
            flexGrow: 1,
        },
        title: {
            flexGrow: 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        direction: theme.direction,
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: drawerWidth,
        },
        linkStyling: {
            textDecoration: 'none',
            color: 'inherit'
        },
        languageMenu: {
            width: 'fit-content'
        }
    })
};

const LoginLink = React.forwardRef((props, ref) => (
    <RouterLink replace={true} ref={ref} {...props}/>
));

const availableLanguages = ['en', 'nl'];


class Header extends Component {

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logoutUser: PropTypes.func.isRequired,
        clearSearch: PropTypes.func.isRequired,
        current_order: PropTypes.object,
        websocket: PropTypes.object.isRequired,
    };

    state = {
        open: false,
        anchorEl: null
    };

    render() {
        const {isAuthenticated, user, isStaff, userSearchEngines, ln} = this.props.auth;
        const {classes, t, i18n, websocket} = this.props;
        const {open} = this.state;

        let MenuLinksUser = [];
        if (user && user.parent_company && !['Supplier'].includes(user.parent_company.license)) {
            MenuLinksUser.push(
                {
                    name: 'search',
                    linkTo: '/',
                    icon: <Search/>
                }
            )
        }
        MenuLinksUser.push(
            {
                name: 'TENDER_REQUEST',
                linkTo: '/tender-requests',
                icon: <AssignmentOutlined/>
            }
        )
        if (user && user.parent_company && ['EDI', 'Supplier'].includes(user.parent_company.license)) {
            MenuLinksUser.push(
                {
                    name: 'ORDERS',
                    linkTo: '/orders',
                    icon: <Assignment/>
                }
            )
        }

        const MenuLinksAdmin = [
            {
                name: 'roles',
                linkTo: '/roles',
                icon: <CenterFocusStrongOutlined/>
            },
            {
                name: 'licenses',
                linkTo: '/licenses',
                icon: <Gavel/>
            },
            {
                name: 'companies',
                linkTo: '/companies',
                icon: <Business/>
            },
            {
                name: 'searchEngines',
                linkTo: '/background_search',
                icon: <YoutubeSearchedFor/>
            },
            {
                name: 'users',
                linkTo: '/users',
                icon: <Group/>
            },
            {
                name: 'SUPPLIERS',
                linkTo: '/suppliers2ba',
                icon: <Shop/>
            }
        ];

        const MenuLinksEsearchAdmin = [
            {
                name: t('ADD_ADMIN_ORDER'),
                linkTo: '/admin-order',
                icon: <Create/>
            },
        ];

        const handleDrawerOpen = () => {
            this.setState({open: true});
        };

        const handleDrawerClose = () => {
            this.setState({open: false});
        };

        const changeLanguage = event => {
            i18n.changeLanguage(event);
        };

        const clearSearchButton = event => {
            event.preventDefault();
            this.props.clearSearch();
        };

        const basket_count = this.props.current_order.basket.length;

        const openMenu = Boolean(this.state.anchorEl);

        const handleMenu = event => {
            this.setState({anchorEl: event.currentTarget});
        };

        const handleClose = () => {
            handleDrawerClose();
            this.setState({anchorEl: null});
        };

        const closeSocket = socket => () => {
            socket.instance.close();
        };

        return (
            <div className={classes.root}>
                <AppBar position='static' className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                    <Toolbar>{isAuthenticated ?
                        <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleDrawerOpen}
                                    className={clsx(classes.menuButton, open && classes.hide)}>
                            <MenuIcon/>
                        </IconButton> : ''}
                        {isAuthenticated ?
                            <Typography variant='h6' className={classes.title} onClick={clearSearchButton}>
                                <RouterLink to={'/'} className={classes.linkStyling}>
                                    eSearch
                                </RouterLink>
                            </Typography> :
                            <Typography variant='h6' className={classes.title}>
                                eSearch
                            </Typography>
                        }
                        {isAuthenticated ?
                            <div>
                                {user && user.parent_company && ['EDI'].includes(user.parent_company.license) &&
                                    <IconButton aria-label='shopping_cart' color='inherit'
                                                disabled={this.props.current_order.id === null}>
                                        <RouterLink to={`/cart/${this.props.current_order.id}`} className={classes.linkStyling}>
                                            <Badge badgeContent={basket_count} color='secondary'>
                                                <ShoppingCart/>
                                            </Badge>
                                        </RouterLink>
                                    </IconButton>}
                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleMenu}
                                    color='inherit'
                                >
                                    <AccountCircle/>
                                </IconButton>
                                <Menu
                                    id='menu-appbar'
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    open={openMenu}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                >
                                    {user && user.parent_company && ['EDI', 'Supplier'].includes(user.parent_company.license) &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                        }}>
                                            <RouterLink to={'/orders'} className={classes.linkStyling}>
                                                {t('ORDERS')}
                                            </RouterLink>
                                        </MenuItem>
                                    }
                                    <MenuItem onClick={() => {
                                        handleClose();
                                    }}>
                                        <RouterLink to={'/tender-requests'} className={classes.linkStyling}>
                                            {t('TENDER_REQUEST')}
                                        </RouterLink>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        if (websocket.instance != null) {
                                            websocket.instance.close();
                                        }
                                        this.props.logoutUser();
                                    }}>
                                        {t('logOut')}
                                    </MenuItem>
                                </Menu>
                            </div> : ''
                        }
                        <SelectLanguage value={ln.slice(0, 2)} onChange={event => changeLanguage(event)}
                                        lng={availableLanguages}
                        />
                    </Toolbar>
                </AppBar>
                {isAuthenticated ?
                    <Drawer
                        className={classes.drawer}
                        variant='persistent'
                        anchor='left'
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader} onClick={handleDrawerClose}>
                            <IconButton onClick={handleDrawerClose}>
                                {classes.direction === 'ltr' ? <ChevronRight/> : <ChevronLeft/>}
                            </IconButton>
                        </div>
                        <Divider/>
                        <List onClick={handleDrawerClose}>
                            {MenuLinksUser.map((linkObject, index) => (
                                <ListItemLink to={linkObject.linkTo} primary={t(linkObject.name)}
                                              key={`${linkObject.name}-${linkObject.linkTo}`}
                                              icon={linkObject.icon}
                                              onClick={clearSearchButton}
                                />
                            ))}
                        </List>
                        <Divider/>
                        {isStaff ?
                            <List onClick={handleDrawerClose}>
                                {MenuLinksAdmin.map((linkObject, index) => (
                                    <ListItemLink to={linkObject.linkTo} primary={t(linkObject.name)}
                                                  key={`${linkObject.name}-${linkObject.linkTo}`}
                                                  icon={linkObject.icon}
                                                  onClick={handleDrawerClose}
                                    />
                                ))}
                                {/*<Divider/>*/}
                                {/*<ListItemLink primary={t('registerUser')} to='register' icon={<PersonAdd/>}/>*/}
                            </List>
                            : ''}
                        <Divider/>
                        {user.is_esearch_admin &&
                            <List onClick={handleDrawerClose}>
                                {MenuLinksEsearchAdmin.map((menuItem) => (
                                    <ListItemLink to={menuItem.linkTo} primary={t(menuItem.name)}
                                                  key={`${menuItem.name}-${menuItem.linkTo}`}
                                                  icon={menuItem.icon}
                                                  onClick={handleDrawerClose}
                                    />
                                ))}
                            </List>
                        }
                        {/*{userSearchEngines ? <UserEngines/> : ''}*/}

                    </Drawer> : ''}
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader}>
                        <RegisteredRoutes/>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.authReducer,
    userSearchEngines: state.authReducer.userSearchEngines,
    current_order: state.ordersReducer.current_order,
    websocket: state.websocketReducer
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    logoutUser,
    clearSearch
})(withTranslation('header')(Header)));