import {LOADING, STOP_LOADING} from "../actions/types";

const initialState = {
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return {
                loading: true
            };
        case STOP_LOADING:
            return {
                loading: false
            };
        default:
            return state
    }
}