import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Container} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import TenderRequestForm from "./TenderRequestForm";
import TenderRequestItemsTable from "./TenderRequestItemsTable";
import TenderRequestItemsForm from "./TenderRequestItemForm";
import {
    clearTenderRequestItemData,
    createTenderRequest,
    createTenderRequestItem,
    createTenderRequestItemsFromCsv,
    downloadTenderRequestItemImages,
    getTenderRequestsById,
    reopenTenderRequest,
    reopenTenderRequestItem,
    sendTenderRequest,
    setTenderRequestItemData,
    updateTenderRequest,
    updateTenderRequestItem,
    uploadTenderRequestItemImages
} from "../../../actions/tenderRequest";
import {useParams} from "react-router-dom";
import {emptyTenderRequestItem, TenderRequestStatus} from "../../../reducers/tenderRequest";
import {getAllSuppliers2ba} from "../../../actions/supplier";


const TenderRequest = ({
                           currentTenderRequest,
                           createTenderRequest,
                           updateTenderRequest,
                           getTenderRequestsById,
                           sendTenderRequest,
                           reopenTenderRequest,
                           currentTenderRequestItem,
                           createTenderRequestItem,
                           updateTenderRequestItem,
                           setTenderRequestItemData,
                           clearTenderRequestItemData,
                           uploadTenderRequestItemImages,
                           downloadTenderRequestItemImages,
                           createTenderRequestItemsFromCsv,
                           reopenTenderRequestItem,
                           user,
                           getAllSuppliers2ba,
                           suppliersList,
                           t,
                       }) => {
    const {id} = useParams();
    const [tenderRequestFormData, setTenderRequestFormData] = useState(currentTenderRequest);
    const [tenderRequestItemFormData, setTenderRequestItemFormData] = useState(emptyTenderRequestItem);

    useEffect(() => {
        getAllSuppliers2ba('True', 'True');
        if (id) {
            getTenderRequestsById(id)
        }
    }, [id]);

    useEffect(() => {
        setTenderRequestItemFormData(currentTenderRequestItem)
    }, [currentTenderRequestItem]);

    useEffect(() => {
        if (!currentTenderRequest.id) {
            currentTenderRequest = {
                ...currentTenderRequest, department: user.department,
                delivery_location: user.branch_offices.find(item => item.id === user.branch_office).city
            }
        }
        setTenderRequestFormData(currentTenderRequest)
    }, [currentTenderRequest])

    const handleSaveTenderRequest = () => {
        if (!tenderRequestFormData.id) {
            createTenderRequest(tenderRequestFormData)
        } else {
            updateTenderRequest(tenderRequestFormData)
        }
    }

    const handleSaveTenderRequestItem = () => {
        if (!tenderRequestItemFormData.id) {
            createTenderRequestItem(tenderRequestFormData.id, tenderRequestItemFormData)
        } else {
            updateTenderRequestItem(tenderRequestItemFormData)
        }
    }

    const updateTenderRequestFormData = (newData) => {
        setTenderRequestFormData({
            ...tenderRequestFormData,
            ...newData
        });
    };

    const updateTenderRequestItemFormData = (newData) => {
        setTenderRequestItemFormData({
            ...tenderRequestItemFormData,
            ...newData
        });
    };

    const selectTenderRequestItem = (tenderRequestItem) => {
        setTenderRequestItemData(tenderRequestItem)
    }

    const handleCsvUpload = (csvFile) => {
        createTenderRequestItemsFromCsv(currentTenderRequest.id, csvFile)
    }

    const isTenderRequestUpdatable = (!user.is_supplier && (!tenderRequestFormData.id || tenderRequestFormData.status === TenderRequestStatus.DRAFT)) ||
        (tenderRequestFormData.status === TenderRequestStatus.PENDING && user.is_esearch_admin)

    return (
        <Container maxWidth={false}>
            <div>
                <TenderRequestForm t={t}
                                   tenderRequestFormData={tenderRequestFormData}
                                   saveTenderRequest={handleSaveTenderRequest}
                                   reopenTenderRequest={reopenTenderRequest}
                                   updateTenderRequestFormData={updateTenderRequestFormData}
                                   sendTenderRequest={sendTenderRequest}
                                   suppliersList={suppliersList}
                                   user={user}
                                   isUpdatable={isTenderRequestUpdatable}
                />

            </div>
            {tenderRequestFormData.id && isTenderRequestUpdatable &&
                <TenderRequestItemsForm t={t}
                                        saveTenderRequestItem={handleSaveTenderRequestItem}
                                        tenderRequestItemFormData={tenderRequestItemFormData}
                                        updateTenderRequestItemFormData={updateTenderRequestItemFormData}
                                        clearForm={clearTenderRequestItemData}
                                        uploadTenderRequestItemImages={uploadTenderRequestItemImages}
                                        downloadTenderRequestItemImages={downloadTenderRequestItemImages}
                                        handleCsvUpload={handleCsvUpload}
                                        isUpdatable={isTenderRequestUpdatable}
                />
            }
            <TenderRequestItemsTable t={t} items={currentTenderRequest.items}
                                     status={tenderRequestFormData.status}
                                     user={user}
                                     selectTenderRequestItem={selectTenderRequestItem}
                                     downloadTenderRequestItemImages={downloadTenderRequestItemImages}
                                     selectedSuppliersList={currentTenderRequest.selected_suppliers}
                                     reopenTenderRequestItem={reopenTenderRequestItem}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    currentTenderRequest: state.tenderRequestReducer.currentTenderRequest,
    currentTenderRequestItem: state.tenderRequestReducer.currentTenderRequestItem,
    user: state.authReducer.user,
    suppliersList: state.supplierReducer.suppliers2ba.items
});

export default (connect(mapStateToProps, {
    createTenderRequest,
    updateTenderRequest,
    getTenderRequestsById,
    sendTenderRequest,
    reopenTenderRequest,
    getAllSuppliers2ba,
    createTenderRequestItem,
    updateTenderRequestItem,
    setTenderRequestItemData,
    clearTenderRequestItemData,
    uploadTenderRequestItemImages,
    downloadTenderRequestItemImages,
    createTenderRequestItemsFromCsv,
    reopenTenderRequestItem
})(withTranslation(['common', 'tender_module'])(TenderRequest)));