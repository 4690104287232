import React, {Component} from 'react';
import {CircularProgress, Container} from "@material-ui/core";

class Loading extends Component {
    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </div>
        );
    }
}

export default Loading;