import React, {Component} from 'react';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {requestResetPassword} from "../../actions/auth";

import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from 'react-i18next';
import {TextField, Button, Typography, Container, Avatar, CircularProgress, Link} from "@material-ui/core";
import {EmailOutlined} from '@material-ui/icons';


const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        loadingProgress: {
            position: 'absolute',
            zIndex: 1
        }
    })
};

const ToLogin = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} to="/login" {...props} />
));

class RequestResetPassword extends Component {
    static propTypes = {
        requestResetPassword: PropTypes.func.isRequired,
        resetPasswordState: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool,
        loading: PropTypes.bool.isRequired
    };

    state = {
        email: ''
    };

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    };

    onSubmit = event => {
        event.preventDefault();
        const {email} = this.state;
        this.props.requestResetPassword(email);
        this.setState({
            email: ''
        });
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/"/>
        }
        const {email} = this.state;
        const {classes, t} = this.props;

        if (this.props.resetPasswordState) {
            return <Redirect to="/login"/>
        }

        return (
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <EmailOutlined/>
                    </Avatar>
                    {this.props.loading && <CircularProgress size={57} className={classes.loadingProgress}/>}
                    <Typography component="h1" variant="h5">
                        Email
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                        <TextField
                            disabled={this.props.loading}
                            onChange={this.onChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            autoFocus
                            autoComplete="email"
                        />
                        <Link component={ToLogin}>{t('signIn')}</Link>
                        <Button
                            disabled={this.props.loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('requestPasswordReset')}
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    resetPasswordState: state.authReducer.resetPasswordState,
    loading: state.loadingReducer.loading
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    requestResetPassword
})(withTranslation('login')(RequestResetPassword)));