import {
    CHANNEL_NAME,
    WEBSOCKET_CLOSED,
    WEBSOCKET_CONNECTING,
    WEBSOCKET_ERROR,
    WEBSOCKET_MESSAGED,
    WEBSOCKET_OPENED
} from "../actions/types";

const initialState = {
    instance: null,
    loading: false,
    connected: false,
    error: null,
    message: null,
    channel_name: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case WEBSOCKET_CONNECTING:
            return {
                ...state,
                loading: true,
            };
        case WEBSOCKET_OPENED:
            return {
                ...state,
                connected: true,
                loading: false,
                instance: action.payload.instance
            };
        case WEBSOCKET_MESSAGED:
            return {
                ...state,
                message: action.payload
            };
        case WEBSOCKET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case WEBSOCKET_CLOSED:
            return {
                ...state,
                loading: false,
                connected: false,
                instance: null,
            };
        case CHANNEL_NAME:
            return {
                ...state,
                channel_name: action.payload
            };
        default:
            return state;
    }
}