import React from "react";

import {FormControl, Input, MenuItem, Select} from '@material-ui/core';

export default function SelectEsearchElement(props) {
    const {value, onChange, search_elements, t} = props;
    const [selectedValue, setSelectedValue] = React.useState(value);

    const handleChange = value => {
        setSelectedValue(value.target.value);
        onChange(value.target.value);
    };

    return (
        <FormControl>
            <Select
                value={selectedValue}
                onChange={handleChange}
                input={<Input/>}
            >
                {search_elements.map(element => (
                    <MenuItem key={element.id} value={element.esearch_element} name="element.esearch_element">
                        {t(element.esearch_element)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}