import {websocketOpen, websocketClose, websocketConnect, websocketError, websocketMessage} from '../actions/websocket';

export const initSocket = (uri) => (dispatch, getState) => {
    if (!('WebSocket' in window)) {
        dispatch(websocketError({ error: 'WebSocket is not supported by your browser' }));
        return;
    }

    const socket = new WebSocket(uri);
    dispatch(websocketConnect());

    socket.onopen = () => dispatch(websocketOpen({ instance: socket }));
    socket.onerror = () => dispatch(websocketError({ error: true }));
    socket.onmessage = event => dispatch(websocketMessage({ ...JSON.parse(event.data) }));
    socket.onclose = () => dispatch(websocketClose());
};