import React from 'react';
import { ClipLoader } from 'react-spinners';

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#0384fc",
};

export const SpinnerComponent = () => {
    return (
        <div className="sweet-loading">
            <ClipLoader cssOverride={override} size={15} color={'#36D7B7'} loading={true} />
        </div>
    );
};