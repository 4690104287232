import React, {useEffect, useState} from "react";
import {checkIfOrderIsProcessed, validateQuantityInput} from "../../../utils/orderUtils";
import {IconButton, Toolbar, Tooltip, Typography} from "@material-ui/core";
import {OrderQuantity} from "../OrderQuantity";
import {AddShoppingCart} from "@material-ui/icons";
import {lighten, makeStyles} from "@material-ui/core/styles";


const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 50%',
    },
    orderQuantity: {
        textAlign: 'right'
    }
}));

export const AddToCartToolbar = ({
                                     currentOrder,
                                     selectedItem,
                                     setSelectedItem,
                                     user,
                                     t,
                                     createBasket,
                                     addToBasket,
                                 }) => {
    const classes = useToolbarStyles();
    const minOrderQuantity = selectedItem.MinimumOrderQuantity || 1
    const [orderQuantity, setOrderQuantity] = useState(minOrderQuantity);

    useEffect(() => {
        setOrderQuantity(minOrderQuantity)
    }, [minOrderQuantity]);

    const onSubmit = event => {
        event.preventDefault();
        if (validateQuantityInput(orderQuantity, minOrderQuantity)) {
            onAddToCart(orderQuantity, selectedItem)
        }
    };

    const onAddToCart = (orderQuantity, selectedItem) => {
        const updatedItem = {
            ...selectedItem,
            OrderQuantity: orderQuantity,
            Note: ''
        }
        if (currentOrder.id && !checkIfOrderIsProcessed(currentOrder)) {
            addToBasket(updatedItem, currentOrder)
        } else {
            createBasket(updatedItem, user)
        }
        setSelectedItem({});
    };

    return (
        <Toolbar
            className={`${classes.root} ${selectedItem ? classes.highlight : ''}`}
        >
            <Typography className={classes.title} color="inherit" variant="subtitle1">
                {t('SELECTED')}
            </Typography>
            <div>
                <form onSubmit={onSubmit} noValidate>
                    <div>
                        <OrderQuantity orderQuantity={orderQuantity} minOrderQuantity={minOrderQuantity} t={t}
                                       onChange={setOrderQuantity} label={t('ORDER_QUANTITY')}/>
                        {
                            user && user.parent_company && ['EDI'].includes(user.parent_company.license) &&
                            <Tooltip title="Add to cart">
                                <IconButton aria-label="add_shopping_cart" type='submit'>
                                    <AddShoppingCart/>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </form>
            </div>
        </Toolbar>
    );
};