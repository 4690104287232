import React from "react";

import {IconButton, FormControl, Input, MenuItem, Menu, Avatar} from '@material-ui/core';

export default function SelectLanguage(props) {
    const {value, onChange, lng} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState(value);
    const [selectedIndex, setSelectedIndex] = React.useState(lng.indexOf(value));

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value, index) => {
        setSelectedValue(lng[index]);
        setSelectedIndex(index);
        onChange(lng[index]);
        setAnchorEl(null);
    };

    return (
        <FormControl>
            <IconButton size='small' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar style={{backgroundColor: 'inherit', color: 'inherit'}}>{selectedValue.toUpperCase()}</Avatar>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {lng.map((ln, index) => (
                    <MenuItem
                        key={ln}
                        selected={index === selectedIndex}
                        onClick={e => handleChange(e, index)}>
                        {ln.toUpperCase()}
                    </MenuItem>
                ))}
            </Menu>
        </FormControl>
    );
}