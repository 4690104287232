import axios from 'axios';
import {createMessage, returnErrors} from "./messages";

import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    GET_USER_ENGINES,
    UPDATE_USER_ENGINE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_INIT,
    LOADING,
    STOP_LOADING,
    CLEAR_ORDERS,
    SET_THEME,
    CLEAR_SEARCH,
    CLEAR_ESEARCH
} from "./types";

export const tokenConfig = getState => {
    const token = getState().authReducer.token;
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};

//CHECK TOKEN AND LOAD USER
export const loadUser = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    axios
        .get('/api/auth/user', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            })
        })
};

//GET USER SEARCH ENGINES
export const getUserEngines = () => (dispatch, getState) => {
    axios
        .get('/api/auth/user_search_engines/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_USER_ENGINES,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

//EDIT USER SEARCH ENGINE
export const editUserEngine = (newData, oldData) => (dispatch, getState) => {
    axios
        .patch(`/api/auth/user_search_engines/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_USER_ENGINE,
                newData: res.data,
                oldData: oldData
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

//LOGIN USER
export const loginUser = (credentials) => dispatch => {
    axios
        .post('/api/auth/login', credentials)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            })
        })
};

//LOGOUT USER
export const logoutUser = () => (dispatch, getState) => {
    axios
        .post('/api/auth/logout', null, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LOGOUT_SUCCESS,
            });
            dispatch({
                type: CLEAR_ORDERS,
            });
            dispatch({
                type: CLEAR_SEARCH,
            });
            dispatch({
                type: CLEAR_ESEARCH,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};

//REQUEST RESET PASSWORD
export const requestResetPassword = (email) => dispatch => {
    dispatch({
        type: LOADING
    });
    axios
        .post('/api/auth/reset-password/', {'email': email})
        .then(res => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS
            });
            dispatch({
                type: STOP_LOADING
            });
            dispatch(createMessage({requestResetPassword: 'You will receive Email shortly'}));
            dispatch({
                type: RESET_PASSWORD_INIT
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_PASSWORD_ERROR
            });
            dispatch({
                type: STOP_LOADING
            });
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};

//RESET PASSWORD
export const resetPassword = (credentials) => dispatch => {
    axios
        .post('/api/auth/reset-password/confirm/', credentials)
        .then(res => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS
            });
            dispatch(createMessage({resetPassword: 'Password Set Successfully'}));
            dispatch({
                type: RESET_PASSWORD_INIT
            })
        })
        .catch(err=> {
            dispatch({
                type: RESET_PASSWORD_ERROR
            });
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

//SET DARK/LIGHT THEME MODE
export const setPreferedThemeMode = (mode) => dispatch => {
    dispatch({
        type: SET_THEME,
        payload: mode
    })
};
