import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {registerUser} from "../../actions/users";
import {returnErrors} from "../../actions/messages";
import {getEngines} from "../../actions/esearch";

import {
    Container,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    Input,
    FormControl,
    MenuItem
} from "@material-ui/core";
import Button from '@material-ui/core/Button'
import withStyles from "@material-ui/core/styles/withStyles";


const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: 200,
        },
        title: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
        },
    })
};

class Register extends Component {
    static propTypes = {
        registerUser: PropTypes.func.isRequired,
        esearch: PropTypes.array.isRequired,
        getEngines: PropTypes.func.isRequired,
    };

    state = {
        username: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        company_name: '',
        contact_person: '',
        gln_code: '',
        phone_number: '',
        role: '',
        language: '',
        department: '',
        manager: '',
        password: '',
        password2: '',
        is_staff: false,
        is_active: false,
        search_engines: []
    };

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    };

    onSubmit = event => {
        event.preventDefault();
        const {
            username, first_name, middle_name, last_name, email, company_name, contact_person, gln_code,
            phone_number, role, language, department, manager, password, password2, is_staff, is_active, search_engines
        } = this.state;
        if (password !== password2) {
            this.props.returnErrors({passwords: ' do not match'})
        } else {
            const user = {
                username, first_name, middle_name, last_name, email, company_name, contact_person, gln_code,
                phone_number, role, language, department, manager, password, is_staff, is_active, search_engines
            };
            this.props.registerUser(user);
            this.setState({
                username: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                email: '',
                company_name: '',
                contact_person: '',
                gln_code: '',
                phone_number: '',
                role: '',
                language: '',
                department: '',
                manager: '',
                password: '',
                password2: '',
                is_staff: false,
                is_active: false,
                search_engines: []
            });
        }
    };

    componentDidMount() {
        this.props.getEngines();
    }

    render() {

        const {
            username, first_name, middle_name, last_name, email, company_name, contact_person, gln_code,
            phone_number, role, language, department, manager, password, password2, is_staff, is_active, search_engines
        } = this.state;
        const {classes} = this.props;
        return (
            <div className={classes.paper}>
                <Container className={classes.paper} maxWidth="md">
                    <Typography className={classes.title} variant="h6">
                        Register New User
                    </Typography>
                    <form onSubmit={this.onSubmit}>
                        <div>
                            <TextField className={classes.textField} label="Username" type="text" value={username}
                                       name="username" autoComplete="username"
                                       onChange={this.onChange} variant="outlined" required/>
                            <TextField className={classes.textField} label="First Name" type="text" value={first_name}
                                       name="first_name" onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Middle Name" type="text" value={middle_name}
                                       name="middle_name" onChange={this.onChange} variant="outlined"/>
                        </div>
                        <div>
                            <TextField className={classes.textField} label="Last Name" type="text" value={last_name}
                                       name="last_name" onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Email" type="email" value={email}
                                       name="email" onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Company Name" type="text"
                                       value={company_name}
                                       name="company_name"
                                       onChange={this.onChange} variant="outlined"/>
                        </div>
                        <div>
                            <TextField className={classes.textField} label="Contact Person" type="text"
                                       value={contact_person}
                                       name="contact_person"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="GLN Code" type="text" value={gln_code}
                                       name="gln_code"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Phone Number" type="text"
                                       value={phone_number}
                                       name="phone_number"
                                       onChange={this.onChange} variant="outlined"/>
                        </div>
                        <div>
                            <TextField className={classes.textField} label="Role" type="text" value={role} name="role"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Language" type="text" value={language}
                                       name="language"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Department" type="text" value={department}
                                       name="department"
                                       onChange={this.onChange} variant="outlined"/>
                        </div>
                        <div>
                            <TextField className={classes.textField} label="Manager" type="text" value={manager}
                                       name="manager"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Password" type="password" value={password}
                                       name="password" autoComplete="new-password"
                                       onChange={this.onChange} variant="outlined"/>
                            <TextField className={classes.textField} label="Repeat Password" type="password"
                                       value={password2}
                                       name="password2" autoComplete="new-password"
                                       onChange={this.onChange} variant="outlined"/>
                        </div>
                        <div>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel>
                                    eSearch
                                </InputLabel>
                                <Select
                                    value={search_engines}
                                    name="search_engines"
                                    onChange={this.onChange}
                                    // labelWidth={labelWidth}
                                    multiple
                                    input={<Input/>}
                                >
                                    {this.props.esearch.map(engine => (
                                        <MenuItem key={engine.id} value={engine.name} name="engine.name">
                                            {engine.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControlLabel className={classes.textField}
                                              control={<Switch onChange={this.onChange} checked={is_staff}
                                                               value={is_staff}
                                                               name="is_staff"/>} label="Admin"/>
                        </div>
                        <div>
                            <FormControlLabel className={classes.textField}
                                              control={<Switch onChange={this.onChange} checked={is_active}
                                                               value={is_active}
                                                               name="is_active"/>} label="Active"/>
                        </div>
                        <div>
                            <Button className={classes.textField} variant="outlined" color="primary" type="submit">
                                Add User
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    esearch: state.esearchReducer.esearch
});

export default connect(mapStateToProps, {registerUser, returnErrors, getEngines})(withStyles(useStyles)(Register));