import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import MaterialTable from 'material-table';
import localization from '../../common/MaterialTableLocalization';
import {
    createSupplierCost,
    updateSupplierCost,
    deleteSupplierCost,
    getAvailableSupplierCostTypes,
    getSupplierCosts
} from "../../../actions/supplier";
import {connect} from "react-redux";
import {TextField} from "@material-ui/core";
import SelectDropdown from "../../common/SelectDropdown";

const costFormats = [
    "COST_PER_AMOUNT",
    "FIXED_FEE_UNDER_VALUE",
    "COST_PER_ORDER",
    "PERCENTAGE_OF_AMOUNT"
];
const SupplierCostTable = ({
                               supplier,
                               createSupplierCost,
                               updateSupplierCost,
                               deleteSupplierCost,
                               getSupplierCosts,
                               t,
                               tableIcons,
                           }) => {

    const supplierId = supplier.id
    const supplierCosts = supplier.costs

    const dispatch = useDispatch();
    const [availableCostTypes, setAvailableCostTypes] = useState([]);

    useEffect(() => {
        getSupplierCosts(supplierId);
    }, [supplierId]);

    useEffect(() => {
        (async () => {
            const responseData = await dispatch(getAvailableSupplierCostTypes(supplierId));
            setAvailableCostTypes(responseData);
        })();
    }, [dispatch, supplierCosts]);


    const columns = [
        {
            title: t('company:COST_TYPE'),
            field: 'cost_type',
            sorting: true,
            render: rowData => rowData.cost_type,
            editComponent: ({rowData, onChange}) => {
                const costTypes = rowData.id?[rowData.cost_type, ...availableCostTypes]: availableCostTypes
                return(
                <SelectDropdown defaultValue={rowData.cost_type || costTypes[0]}
                                dropdownValues={costTypes}
                                onChange={onChange}
                                translate={t}/>
            )}
        },
        {
            title: t('company:COST_FORMAT'),
            field: 'cost_format',
            sorting: true,
            render: rowData => rowData.cost_format,
            editComponent: ({rowData, onChange}) => (
                <SelectDropdown defaultValue={rowData.cost_format || costFormats[0]}
                                dropdownValues={costFormats} onChange={onChange}/>
            )
        },
        {
            title: t('company:COST_VALUE'),
            field: 'cost_value',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 0,
                        step: 0.01
                    }}
                    value={rowData.cost_value}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('company:COST_LIMIT'),
            field: 'cost_limit',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 0,
                        step: 0.01
                    }}
                    value={rowData.cost_limit}
                    onChange={e => onChange(e.target.value)}
                />
        },
    ]


    return (
        <MaterialTable
            title={t('company:SUPPLIER_COSTS')}
            icons={tableIcons}
            columns={columns}
            data={supplierCosts}
            options={{
                search: false,
                sorting: true,
                rowStyle: {
                    backgroundColor: 'palette.text.disabled'
                },
                headerStyle: {
                    backgroundColor: 'palette.text.disabled'
                },
            }}
            localization={localization(t)}
            editable={
                {
                    ...(availableCostTypes?.length > 0 && {
                        onRowAdd: supplierCost => new Promise((resolve) => {
                            const newSupplierCost = {
                                ...supplierCost,
                                cost_type: supplierCost.cost_type || availableCostTypes[0],
                                cost_format: supplierCost.cost_format || costFormats[0],
                            };
                            createSupplierCost(newSupplierCost, supplierId);
                            resolve();
                        })
                    }),
                    onRowUpdate: supplierCost => new Promise((resolve) => {
                        updateSupplierCost(supplierCost, supplierId);
                        resolve();
                    }),
                    onRowDelete: supplierCost => new Promise((resolve) => {
                        deleteSupplierCost(supplierCost.id, supplierId);
                        resolve();
                    }),
                }
            }
        />
    )
}


export default (connect(null, {
    createSupplierCost,
    updateSupplierCost,
    deleteSupplierCost,
    getSupplierCosts,
})(SupplierCostTable));