import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Avatar,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import Downshift from 'downshift';
import withStyles from "@material-ui/core/styles/withStyles";
import {clearDirectSearch, executeSearch} from '../../actions/esearch';

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        list: {
            border: '1px solid #4051B5',
        },
    })
};


class SearchEngine extends Component {
    inputRef = React.createRef();

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.websocket.channel_name !== null && nextProps.direct_search === true && nextProps.userSearchEngines !== null) {
            const searchString = nextProps.search_string;
            const channel_name = nextProps.websocket.channel_name;
            const user = nextProps.user;
            const userSearchEngines = nextProps.userSearchEngines;
            const searchQuery = {searchString, user, userSearchEngines, channel_name};
            state.executeSearch(searchQuery);
            state.clearDirectSearch()
        }
        return null
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        executeSearch: PropTypes.func.isRequired,
        userSearchEngines: PropTypes.array,
        websocket: PropTypes.object.isRequired,
        search_string: PropTypes.string,
        search_results_2ba: PropTypes.object,
        direct_search: PropTypes.bool,
        clearDirectSearch: PropTypes.func.isRequired,
    };

    state = {
        searchString: this.props.searchString,
        showIcon: false,
        executeSearch: this.props.executeSearch,
        clearDirectSearch: this.props.clearDirectSearch,
    };

    handleInputChange = event => {
        const value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleSelect = selectedItem => {
        if (selectedItem) {
            this.setState({searchString: selectedItem});
        }
    };

    onSubmit = event => {
        event.preventDefault();
        const {searchString} = this.state;
        const {user, userSearchEngines} = this.props;
        const channel_name = this.props.websocket.channel_name;
        const searchQuery = {searchString, user, userSearchEngines, channel_name};
        this.props.executeSearch(searchQuery);
        this.setState({
            searchString: ''
        })
        this.inputRef.current.blur();
    };
    onMouseDown = event => {
        event.preventDefault()
    };

    render() {
        if (!this.props.isAuthenticated) {
            return <Redirect to="/login"/>
        }
        const {searchString} = this.state;
        const {classes} = this.props;

        const searchHistory = this.props.user.settings.search_history
            .filter(item =>
                item.toLowerCase().startsWith(searchString.toLowerCase())
            )

        return (
            <Container maxWidth='lg'>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Search/>
                    </Avatar>
                    <Downshift
                        inputValue={searchString}
                        onChange={this.handleSelect}
                        itemToString={item => (item ? item.label : '')}
                    >
                        {({
                              getInputProps,
                              getItemProps,
                              getMenuProps,
                              isOpen,
                              highlightedIndex,
                              selectedItem,
                              openMenu,
                              clearSelection
                          }) => (
                            <div style={{width: '100%'}}>
                                <form className={classes.form} noValidate onSubmit={(e) => {
                                    this.onSubmit(e);
                                    clearSelection();
                                }}>
                                    <FormControl variant="outlined" fullWidth>
                                        <OutlinedInput
                                            {...getInputProps({
                                                autoComplete: 'off',
                                                required: true,
                                                autoFocus: true,
                                                onChange: (e) => {
                                                    this.handleInputChange(e);
                                                    clearSelection()
                                                },
                                                name: 'searchString',
                                                onFocus: () => openMenu(),
                                                inputRef: this.inputRef,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton type="submit" onMouseDown={this.onMouseDown}>
                                                            <Search/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            })}
                                        />
                                    </FormControl>
                                </form>
                                {isOpen && searchHistory.length > 0 && (
                                    <div {...getMenuProps()}>
                                        <List className={classes.list}>
                                            {searchHistory
                                                .map((item, index) => (
                                                    <ListItem
                                                        {...getItemProps({
                                                            key: item.label,
                                                            index,
                                                            item,
                                                            selected: highlightedIndex === index,
                                                            style: {
                                                                backgroundColor:
                                                                    highlightedIndex === index ? '#bde4ff' : null,
                                                                fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                            },
                                                        })}
                                                    >
                                                        <ListItemIcon>
                                                            <Search/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={item}/>
                                                    </ListItem>
                                                ))}
                                        </List>
                                    </div>
                                )}
                            </div>
                        )}
                    </Downshift>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    user: state.authReducer.user,
    userSearchEngines: state.authReducer.userSearchEngines,
    websocket: state.websocketReducer,
    search_results_2ba: state.esearchReducer.search_results_2ba,
    search_string: state.esearchReducer.search_string,
    direct_search: state.esearchReducer.direct_search,
});

export default connect(mapStateToProps, {executeSearch, clearDirectSearch})(withStyles(useStyles)(SearchEngine));