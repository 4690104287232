import {supportedCurrencySymbols} from "./constants";

export const OrderStatus = {
    CREATED: 'CREATED',
    SENT: 'SENT',
    SEND_FAILED: 'SEND_FAILED',
    INTEGRATION_FAILED: 'INTEGRATION_FAILED'
};

export const validateQuantityInput = (orderQuantity, minOrderQuantity) => {
    return orderQuantity >= minOrderQuantity && (minOrderQuantity - orderQuantity) % minOrderQuantity === 0
}

export const formatPrice = (currencyCode, price) => {
    const currencySymbol = supportedCurrencySymbols[currencyCode] || supportedCurrencySymbols.EUR
    return `${currencySymbol} ${parseFloat(price).toFixed(2)}`
}

export const checkIfOrderIsProcessed = (order) => {
    return ![OrderStatus.CREATED, OrderStatus.SEND_FAILED].includes(order.status)
}