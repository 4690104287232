import {
    WEBSOCKET_CLOSED,
    WEBSOCKET_ERROR,
    WEBSOCKET_OPENED,
    WEBSOCKET_CONNECTING,
    CHANNEL_NAME,
    WEBSOCKET_TRADE_ITEMS,
    ADD_BACKGROUND_SEARCH
} from "./types";
import {createMessage, returnErrors} from "./messages";

export const websocketConnect = () => (dispatch, getState) => {
    dispatch({
        type: WEBSOCKET_CONNECTING
    })
};

export const websocketOpen = (socket) => (dispatch, getState) => {
    dispatch({
        type: WEBSOCKET_OPENED,
        payload: socket
    });
};

export const websocketMessage = (message) => (dispatch, getState) => {
    if(message.hasOwnProperty('channel_name')){
        dispatch({
            type: CHANNEL_NAME,
            payload: message.channel_name
        })
    } else if (message.hasOwnProperty('engine_id')) {
        dispatch({
            type: ADD_BACKGROUND_SEARCH,
            payload: message.payload,
            id: message.id
        })
    } else {
        dispatch({
            type: WEBSOCKET_TRADE_ITEMS,
            payload: message.payload,
            id: message.id
        })
    }
};

export const websocketError = (message) => (dispatch, getState) => {
    dispatch({
        type: WEBSOCKET_ERROR,
        payload: message
    });
    dispatch(returnErrors(message))
};

export const websocketClose = () => (dispatch, getState) => {
    dispatch({
        type: WEBSOCKET_CLOSED
    })
};