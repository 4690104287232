import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {
    Avatar,
    IconButton,
    Container,
    FormControl,
    OutlinedInput,
    InputAdornment,
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import {executeSearch, clearDirectSearch} from '../../actions/esearch';

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        iconButton: {
            padding: 10,
        },
    })
};


class SearchEngine extends Component {

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.websocket.channel_name !== null && nextProps.direct_search === true && nextProps.userSearchEngines !== null) {
            const searchString = nextProps.search_string;
            const channel_name = nextProps.websocket.channel_name;
            const user = nextProps.user;
            const userSearchEngines = nextProps.userSearchEngines;
            const searchQuery = {searchString, user, userSearchEngines, channel_name};
            state.executeSearch(searchQuery);
            state.clearDirectSearch()
        }
        return null
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        executeSearch: PropTypes.func.isRequired,
        userSearchEngines: PropTypes.array,
        websocket: PropTypes.object.isRequired,
        search_string: PropTypes.string,
        search_results_2ba: PropTypes.object,
        direct_search: PropTypes.bool,
        clearDirectSearch: PropTypes.func.isRequired,
    };
    state = {
        searchString: this.props.searchString,
        showIcon: false,
        executeSearch: this.props.executeSearch,
        clearDirectSearch: this.props.clearDirectSearch,
    };

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    };

    onSubmit = event => {
        event.preventDefault();
        const {searchString} = this.state;
        const {user, userSearchEngines} = this.props;
        const channel_name = this.props.websocket.channel_name;
        const searchQuery = {searchString, user, userSearchEngines, channel_name};
        this.props.executeSearch(searchQuery);
        this.setState({
            searchString: ''
        })
    };
    onMouseDown = event => {
        event.preventDefault()
    };

    onFocus = event => {
        this.setState({showIcon: true})
    };
    onBlur = event => {
        this.setState({showIcon: false})
    };

    render() {
        if (!this.props.isAuthenticated) {
            return <Redirect to="/login"/>
        }
        const {searchString} = this.state;
        const {classes} = this.props;
        return (
            <Container maxWidth="md">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Search/>
                    </Avatar>
                    <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                        <FormControl variant="outlined" fullWidth>
                            <OutlinedInput
                                autoComplete="off"
                                required
                                autoFocus
                                notched
                                fullWidth
                                onBlur={this.onBlur}
                                onFocus={this.onFocus}
                                onChange={this.onChange}
                                type="text"
                                value={searchString}
                                name="searchString"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton type="submit" onClick={this.onSubmit}
                                                    onMouseDown={this.onMouseDown}>
                                            {this.state.showIcon ? <Search/> : ''}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </form>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    user: state.authReducer.user,
    userSearchEngines: state.authReducer.userSearchEngines,
    websocket: state.websocketReducer,
    search_results_2ba: state.esearchReducer.search_results_2ba,
    search_string: state.esearchReducer.search_string,
    direct_search: state.esearchReducer.direct_search,
});

export default connect(mapStateToProps, {executeSearch, clearDirectSearch})(withStyles(useStyles)(SearchEngine));