import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Box, Button, Container, Grid, MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import {DefaultSortProducts} from "../../../utils/constants";
import {saveUserSettings} from "../../../actions/users";

const useStyles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        textAlign: 'center'
    },
    formContainer: {
        width: '50%',
        padding: theme.spacing(3),
        textAlign: 'left',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: '50%',
    }
});

const UserSettings = ({
                          user,
                          saveUserSettings,
                          t,
                          classes,
                      }) => {
    const history = useHistory();
    const [userSettingsFormData, setUserSettingsFormData] = useState(user.settings);

    const handleChange = (event) => {
        const {name, value} = event.target;

        setUserSettingsFormData({
            ...userSettingsFormData,
            [name]: value
        })
    };

    const handleSave = (e) => {
        e.preventDefault()
        saveUserSettings(userSettingsFormData)
        history.push("/#")
    };

    return (
        <Container maxWidth="lg">
            <Box className={classes.container}>
                <Paper className={classes.formContainer} elevation={3}>
                    <Typography variant="h5" className={classes.title}>
                        {t('USER_SETTINGS')}
                    </Typography>

                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                className={classes.textField}
                                select
                                fullWidth
                                margin="dense"
                                label={t('DEFAULT_SORT_PRODUCTS')}
                                name="default_sort_products"
                                variant="outlined"
                                InputLabelProps={{shrink: true}}
                                SelectProps={{
                                    displayEmpty: true,
                                    value: userSettingsFormData.default_sort_products,
                                    onChange: handleChange
                                }}
                            >
                                {Object.entries(DefaultSortProducts).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>{t(key)}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item className={classes.buttonContainer}>
                            <Button className={classes.button} variant="contained" color="primary" onClick={handleSave}>
                                {t('SAVE')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    saveUserSettings
})(withTranslation(['common'])(UserSettings)));