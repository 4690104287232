import React, {useState} from "react";
import {FormControl, MenuItem, Select} from '@material-ui/core';

const SelectDropdown = ({
                            defaultValue,
                            dropdownValues,
                            onChange,
                            idField,
                            displayField,
                            valueField = idField,
                            translate
                        }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const handleChange = event => {
        setSelectedValue(event.target.value);
        onChange(event.target.value);
    };

    let menuItems;

    if (!idField || !displayField) {
        menuItems = dropdownValues.map((item, index) => (
            <MenuItem key={index} value={item}>
                {translate ? translate(item) : item}
            </MenuItem>
        ));
    } else {
        menuItems = dropdownValues.map((item) => (
            <MenuItem key={item[idField]} value={item[valueField]}>
                {translate ? translate(item[displayField]) : item[displayField]}
            </MenuItem>
        ));
    }

    return (
        <FormControl>
            <Select
                value={selectedValue}
                onChange={(e) => handleChange(e)}
            >
                {menuItems}
            </Select>
        </FormControl>
    );
}


export default SelectDropdown;