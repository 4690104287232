import axiosInterceptor from "../interceptor";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig} from "./auth";
import {
    GET_TENDER_REQUESTS,
    GET_TENDER_REQUEST_BY_ID,
    SAVE_TENDER_REQUEST,
    CLEAR_TENDER_REQUEST,
    CREATE_TENDER_REQUEST_ITEM,
    DELETE_TENDER_REQUEST_ITEM,
    UPDATE_TENDER_REQUEST_ITEM,
    UPDATE_TENDER_REQUEST_ITEM_OFFER,
    CREATE_TENDER_REQUEST_ITEM_OFFER,
    CLEAR_TENDER_REQUEST_ITEM,
    SET_TENDER_REQUEST_ITEM,
    UPLOAD_TENDER_REQUEST_ITEM_IMAGES,
    SEND_TENDER_REQUEST,
    CREATE_TENDER_REQUEST_ITEM_FROM_CSV,
    GET_TENDER_REQUEST_ITEM_OFFERS,
    REOPEN_TENDER_REQUEST,
} from "./types";


export const getTenderRequests = (statuses, showAllUsersTenders, page, pageSize) => (dispatch, getState) => {
    const params = {
        status: statuses.length > 0 ? statuses : [''], show_all_users_tenders: showAllUsersTenders,
        page, page_size: pageSize
    }

    axiosInterceptor
        .get('/api/tender_request/', {
            params: params,
            ...tokenConfig(getState)
        })
        .then(res => {
            dispatch({
                type: GET_TENDER_REQUESTS,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getTenderRequestsById = id => (dispatch, getState) => {
    axiosInterceptor
        .get(`/api/tender_request/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_TENDER_REQUEST_BY_ID,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const createTenderRequest = (tenderRequest) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/tender_request/`, tenderRequest, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({tenderRequest: 'TENDER_REQUEST_SAVED_SUCCESSFULLY'}));
            window.location.replace(`/#/tender-request/${res.data.id}`)
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const updateTenderRequest = (tenderRequest) => (dispatch, getState) => {
    axiosInterceptor
        .put(`/api/tender_request/${tenderRequest.id}/`, tenderRequest, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({tenderRequest: 'TENDER_REQUEST_SAVED_SUCCESSFULLY'}));
            dispatch({
                type: SAVE_TENDER_REQUEST,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const clearTenderRequestData = () => (dispatch) => {
    dispatch({
        type: CLEAR_TENDER_REQUEST,
    });
};

export const sendTenderRequest = (tenderRequestId) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/tender_request/${tenderRequestId}/send`, null, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({message: 'TENDER_REQUEST_SENT_SUCCESSFULLY'}));
            dispatch({
                type: SEND_TENDER_REQUEST,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const reopenTenderRequest = (tenderRequestId) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/tender_request/${tenderRequestId}/reopen`, null, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({message: 'TENDER_REQUEST_REOPENED_SUCCESSFULLY'}));
            dispatch({
                type: REOPEN_TENDER_REQUEST,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const createTenderRequestItem = (tenderRequestId, tenderRequestItem) => (dispatch, getState) => {
    const data = {
        ...tenderRequestItem,
        tender_request: tenderRequestId,
    }
    axiosInterceptor
        .post(`/api/tender_request_item/`, data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({tenderRequestItem: 'TENDER_REQUEST_ITEM_SAVED_SUCCESSFULLY'}));
            dispatch({
                type: CREATE_TENDER_REQUEST_ITEM,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const updateTenderRequestItem = (tenderRequestItem) => (dispatch, getState) => {
    axiosInterceptor
        .put(`/api/tender_request_item/${tenderRequestItem.id}/`, tenderRequestItem, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({tenderRequestItem: 'TENDER_REQUEST_ITEM_SAVED_SUCCESSFULLY'}));
            dispatch({
                type: UPDATE_TENDER_REQUEST_ITEM,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const deleteTenderRequestItem = (tenderRequestItemId) => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/tender_request_item/${tenderRequestItemId}/`, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({deleteTenderRequestItem: 'TENDER_REQUEST_ITEM_DELETED_SUCCESSFULLY'}));
            dispatch({
                type: DELETE_TENDER_REQUEST_ITEM,
                payload: tenderRequestItemId
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const setTenderRequestItemData = (tenderRequestItem) => (dispatch) => {
    dispatch({
        type: SET_TENDER_REQUEST_ITEM,
        payload: tenderRequestItem
    });
};

export const clearTenderRequestItemData = () => (dispatch) => {
    dispatch({
        type: CLEAR_TENDER_REQUEST_ITEM,
    });
};

export const createTenderRequestItemOffer = (tenderRequestItemOffer) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/tender_request_item_offer/`, tenderRequestItemOffer, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: CREATE_TENDER_REQUEST_ITEM_OFFER,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const updateTenderRequestItemOffer = (tenderRequestItemOffer) => (dispatch, getState) => {
    axiosInterceptor
        .put(`/api/tender_request_item_offer/${tenderRequestItemOffer.id}/`, tenderRequestItemOffer, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_TENDER_REQUEST_ITEM_OFFER,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const deleteTenderRequestItemOffer = (id, tenderRequestItemId) => (dispatch, getState) => {
    const params = {tender_request_item_id: tenderRequestItemId}

    axiosInterceptor
        .delete(`/api/tender_request_item_offer/${id}/`, {params, ...tokenConfig(getState)})
        .then(() => {
            dispatch(createMessage({deleteTenderRequestItem: 'TENDER_REQUEST_ITEM_OFFER_DELETED_SUCCESSFULLY'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};


export const uploadTenderRequestItemImages = (tenderRequestItemId, images) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();

    images.forEach(image => {
        formData.append('image', image);
    });

    axiosInterceptor
        .post(`/api/tender_request_item/${tenderRequestItemId}/image`, formData, config)
        .then(res => {
            dispatch(createMessage({uploadSupplierLogo: 'IMAGE_UPLOADED_SUCCESSFULLY'}));
            dispatch({
                type: UPLOAD_TENDER_REQUEST_ITEM_IMAGES,
                payload: {...res.data, id: tenderRequestItemId}
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};


export const downloadTenderRequestItemImages = (tenderRequestItemId) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'application/json; application/zip';
    config['responseType'] = 'blob';
    axiosInterceptor
        .get(`/api/tender_request_item/${tenderRequestItemId}/image`, config)
        .then(res => {
            const blob = new Blob([res.data], {type: 'application/zip'});
            const objectUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = res.headers['content-disposition'];
            link.click();
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const createTenderRequestItemsFromCsv = (tenderRequestId, csvFile) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('tender_request_id', tenderRequestId);
    formData.append('file', csvFile);

    axiosInterceptor
        .post(`api/upload/tender_request_item`, formData, config)
        .then(res => {
            dispatch(createMessage({createFromCsv: 'TENDER_REQUEST_ITEMS_CREATED_SUCCESSFULLY'}));
            dispatch({
                type: CREATE_TENDER_REQUEST_ITEM_FROM_CSV,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getTenderRequestItemOffers = (tenderRequestItemId, page, pageSize) => (dispatch, getState) => {
    const params = {tender_request_item_id: tenderRequestItemId, page, page_size: pageSize}

    axiosInterceptor
        .get('/api/tender_request_item_offer/', {
            params: params,
            ...tokenConfig(getState)
        })
        .then(res => {
            dispatch({
                type: GET_TENDER_REQUEST_ITEM_OFFERS,
                payload: {
                    tenderRequestItemId: tenderRequestItemId,
                    offers: res.data
                }
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const reopenTenderRequestItem = (tenderRequestItemId) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/tender_request_item/${tenderRequestItemId}/reopen`, null, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({message: 'TENDER_REQUEST_ITEM_REOPENED_SUCCESSFULLY'}));
            dispatch({
                type: REOPEN_TENDER_REQUEST,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

