import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {withStyles} from "@material-ui/core/styles";
import {Tooltip} from '@material-ui/core';
import {Info} from '@material-ui/icons'
import localization from '../../common/MaterialTableLocalization';
import {
    createTenderRequestItemOffer,
    updateTenderRequestItemOffer,
    deleteTenderRequestItemOffer,
    getTenderRequestItemOffers
} from "../../../actions/tenderRequest";
import {createBasket, addToBasket,} from "../../../actions/orders";
import {connect} from "react-redux";
import {Checkbox, TableCell, TextField} from "@material-ui/core";
import {AddToCartToolbar} from "../common/AddToCartToolbar";
import {TenderRequestStatus} from "../../../reducers/tenderRequest";
import SelectDropdown from "../../common/SelectDropdown";
import {PAGE_SIZE, supportedCurrencies} from "../../../utils/constants";
import {calculatePage} from "../../../utils/tableUtils";
import TenderRequestItemOfferFormModal from "./TenderRequestItemOfferFormModal";

const useStyles = () => {
    return ({
        tooltip: {
            fontSize: "20px",
            maxWidth: '1000px'
        },
    })
};

const TenderRequestItemOffersTable = ({
                                          tenderRequestItem,
                                          createTenderRequestItemOffer,
                                          updateTenderRequestItemOffer,
                                          deleteTenderRequestItemOffer,
                                          getTenderRequestItemOffers,
                                          tenderRequestItemStatus,
                                          t,
                                          offers,
                                          tableIcons,
                                          user,
                                          selectedSuppliersList,
                                          createBasket,
                                          addToBasket,
                                          currentOrder,
                                          classes
                                      }) => {

    const [selectedItem, setSelectedItem] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState({});
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    useEffect(() => {
        getTenderRequestItemOffers(tenderRequestItem.id, page + 1, pageSize);
    }, [tenderRequestItem.id, page, pageSize]);

    const handleRowSelection = (rowData) => {
        setSelectedItem(rowData.id === selectedItem.id ? {} : rowData);
    };

    const openModal = (selectedOffer) => {
        setIsModalOpen(true)
        setSelectedOffer(selectedOffer)
    };

    const closeModal = () => {
        setIsModalOpen(false)
    };

    const handleRowDelete = (offerId) => {
        deleteTenderRequestItemOffer(offerId);
        const newPage = calculatePage(page, pageSize, offers.totalCount)
        setPage(newPage)
    }

    const columns = [
        {title: t('ARTICLE_NUMBER'), field: 'article_number', sorting: true},
        {
            title: t('NET_PRICE_PER_UNIT'),
            field: 'net_price',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.net_price}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('CURRENCY'),
            field: 'currency',
            sorting: true,
            render: rowData => rowData.currency,
            editComponent: ({rowData, onChange}) => (
                <SelectDropdown defaultValue={rowData.currency || supportedCurrencies[0]}
                                dropdownValues={supportedCurrencies}
                                onChange={onChange}
                                translate={t}/>
            )
        },
        {
            title: t('MIN_ORDER_QUANTITY'),
            field: 'min_quantity', type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.min_quantity}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('DELIVERY_TIME'),
            field: 'delivery_days',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.delivery_days}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('tender_module:ADDITIONAL_COSTS'),
            field: 'additional_costs',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 0,
                        defaultValue: 0
                    }}
                    value={rowData.additional_costs}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('tender_module:ADDITIONAL_INFO'),
            render: rowData => (
                rowData.additional_info ? (
                    <Tooltip title={rowData.additional_info} classes={{tooltip: classes.tooltip}}>
                        <Info style={{cursor: 'pointer'}}/>
                    </Tooltip>
                ) : null
            ),
        },
    ]

    if (!user.is_supplier) {
        columns.unshift({title: t('SUPPLIER_NAME'), field: 'supplier_name', sorting: true})
    }

    if (!user.is_supplier && tenderRequestItemStatus === TenderRequestStatus.OPEN) {
        columns.unshift({
            title: '',
            field: 'selected',
            render: (rowData) => (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={rowData.id === selectedItem.id}
                    />
                </TableCell>
            ),
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            editable: 'never'
        },)
    }

    const filterSelectedSuppliers = () => {
        const supplierIds = offers.items.map(item => item.supplier);
        return selectedSuppliersList.filter(item => !supplierIds.includes(item.id))
    }

    return (
        <div>
            {selectedItem.id && user && !user.is_supplier && user.parent_company && ['EDI'].includes(user.parent_company.license) &&
                <AddToCartToolbar
                    currentOrder={currentOrder}
                    selectedItem={{
                        ...selectedItem,
                        tenderRequestItemId: tenderRequestItem.id,
                        description: tenderRequestItem.description,
                        MinimumOrderQuantity: selectedItem.min_quantity
                    }}
                    setSelectedItem={setSelectedItem}
                    user={user}
                    t={t}
                    createBasket={createBasket}
                    addToBasket={addToBasket}
                />}
            <MaterialTable
                title={t('tender_module:OFFERS')}
                icons={tableIcons}
                columns={columns.map(column => ({
                    ...column,
                    cellStyle: {textAlign: 'center', ...column.cellStyle},
                    headerStyle: {textAlign: 'center', ...column.headerStyle}
                }))}
                data={offers.items}
                page={page}
                totalCount={offers.totalCount}
                options={{
                    search: false,
                    sorting: true,
                    rowStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    headerStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    actionsColumnIndex: -1,
                    pageSize,
                }}
                localization={localization(t)}
                onRowClick={(e, rowData) => {
                    if (!user.is_supplier && tenderRequestItemStatus === TenderRequestStatus.OPEN) {
                        handleRowSelection(rowData)
                    }
                }}
                onChangePage={(newPage) => setPage(newPage)}
                onChangeRowsPerPage={(newPageSize) => {
                    setPageSize(newPageSize);
                    setPage(0);
                }}
                editable={tenderRequestItemStatus === TenderRequestStatus.OPEN && (user.is_supplier || user.is_esearch_admin)
                    ? {
                        onRowDelete: item => new Promise((resolve) => {
                            handleRowDelete(item.id);
                            resolve();
                        }),
                    }
                    : {}}

                actions={(tenderRequestItemStatus === TenderRequestStatus.OPEN && (user.is_supplier || user.is_esearch_admin)) ? [
                    (offers.length !== selectedSuppliersList.length || offers?.length === 0) && {
                        icon: tableIcons.Add,
                        tooltip: t('table.body.addTooltip'),
                        isFreeAction: true,
                        onClick: () => {
                            openModal({});
                        }
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: t('table.body.editTooltip'),
                        onClick: (event, rowData) => {
                            setSelectedItem(rowData);
                            openModal(rowData);
                        }
                    }
                ] : []}
            />


            {isModalOpen && (
                    <TenderRequestItemOfferFormModal
                        isOpen={isModalOpen}
                        closeModal={closeModal}
                        currentOffer={selectedOffer}
                        tenderRequestItemId={tenderRequestItem.id}
                        createTenderRequestItemOffer={createTenderRequestItemOffer}
                        updateTenderRequestItemOffer={updateTenderRequestItemOffer}
                        selectedSuppliersList={filterSelectedSuppliers()}
                        user={user}
                        t={t}
                    />
            )}
        </div>
    )
}


const mapStateToProps = state => ({
    currentOrder: state.ordersReducer.current_order,
});


export default withStyles(useStyles)(connect(mapStateToProps, {
    createTenderRequestItemOffer,
    updateTenderRequestItemOffer,
    deleteTenderRequestItemOffer,
    getTenderRequestItemOffers,
    createBasket,
    addToBasket
})(TenderRequestItemOffersTable));