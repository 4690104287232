import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Loading from './Loading'

const AdminRoute = ({component: Component, auth, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <Loading/>;
            } else if (!auth.isStaff) {
                return <Redirect to="/"/>;
            } else {
                return <Component {...props}/>;
            }
        }}
    />
);

const mapStateToProps = state => ({
    auth: state.authReducer
});

export default connect(mapStateToProps)(AdminRoute);