import React, {Component, Fragment, Suspense} from 'react';
import ReactDom from 'react-dom';
import {HashRouter as Router} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import './i18n';

import {Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import {CssBaseline, useMediaQuery} from "@material-ui/core";
import {ThemeProvider, createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

import Alerts from './layout/Alerts';
import Header from './layout/Header';
import Loading from './common/Loading';

import {Provider} from 'react-redux';
import store from '../store';
import {loadUser, setPreferedThemeMode} from "../actions/auth";

import TagManager from 'react-gtm-module';
import {deepOrange, red} from "@material-ui/core/colors";

const tagManagerArgs = {
    gtmId: 'GTM-KDQ89NC'
}

const alertOptions = {
    timeout: 5000,
    position: 'top center',
    transition: 'fade',
};

function ThemedApp() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    let themeSettings;

    if (process.env.STAGE === 'staging') {
        themeSettings = {
            palette: {
                type: prefersDarkMode ? 'dark' : 'light',
                primary: {
                    main: deepOrange[500]
                },
                secondary: {
                    main: red[500]
                }
            },
        }
    }
    else {
        themeSettings = {
            palette: {
                type: prefersDarkMode ? 'dark' : 'light',
            },
        }
    }

    const theme = React.useMemo(
        () =>
            responsiveFontSizes(createMuiTheme(themeSettings)),
        [prefersDarkMode],
    );
    store.dispatch(setPreferedThemeMode(prefersDarkMode));
    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<Loading/>}>
                <App/>
            </Suspense>
        </ThemeProvider>
    )
}

class App extends Component {
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Provider store={store}>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <Router>
                        <Fragment>
                            <CssBaseline>
                                <Alerts/>
                                <Header/>
                            </CssBaseline>
                        </Fragment>
                    </Router>
                </AlertProvider>
            </Provider>
        )
    }
}

TagManager.initialize(tagManagerArgs);
Sentry.init({dsn: "https://85194536ec04bdc90942576e6effe469@o4507066471809024.ingest.us.sentry.io/4507066475741184"});
ReactDom.render(<ThemedApp/>, document.getElementById('app'));
