import React from "react";
import {TextField} from "@material-ui/core";
import {validateQuantityInput} from "../../utils/orderUtils";

export const OrderQuantity = props => {
    const {orderQuantity, minOrderQuantity, onChange, t, label} = props;
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setError(false)
    }, [minOrderQuantity]);

    const handleChange = event => {
        onChange(event.target.valueAsNumber)
        setError(false)
    };

    const onBlur = () => {
        setError(!validateQuantityInput(orderQuantity, minOrderQuantity))
    }

    return (
        <TextField
            inputProps={{
                style: {textAlign: 'right'},
                type: 'number',
                min: minOrderQuantity,
                step: minOrderQuantity,
            }}
            label={label}
            variant="standard"
            value={orderQuantity}
            onChange={handleChange}
            onBlur={onBlur}
            error={error}
            helperText={error ? t("INVALID_QUANTITY_ERROR") : ''}
        />
    );
};