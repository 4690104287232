import React from "react";

import {FormControl, Input, MenuItem, Select} from '@material-ui/core';

export default function SelectOCIElement(props) {
    const {value, onChange, oci_elements} = props;
    const [selectedValue, setSelectedValue] = React.useState(value);

    const handleChange = value => {
        setSelectedValue(value.target.value);
        onChange(value.target.value);
    };

    return (
        <FormControl>
            <Select
                value={selectedValue}
                onChange={handleChange}
                input={<Input/>}
            >
                {oci_elements.map(element => (
                    <MenuItem key={element.id} value={element.oci_element} name="element.oci_element">
                        {element.oci_element}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}