import React from 'react';
import {styled} from '@material-ui/core/styles';
import {Switch, FormControlLabel} from '@material-ui/core';


const StyledSwitch = styled(Switch)(({}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#FFFFFF",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#1FC24C",
    },
}));

export const ToggleSwitch = ({label, labelPlacement, checked, onChange}) => {
    return (
        <FormControlLabel
            control={<StyledSwitch checked={checked} onChange={onChange}/>}
            label={label}
            labelPlacement={labelPlacement}
        />
    );
}
