import React, {createRef} from 'react';
import {useDropzone} from "react-dropzone";
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import {CloudDownload, CloudUpload, ImageSearch} from "@material-ui/icons";
import {tenderRequestItemUnitsOfMeasure} from "../../../utils/constants";
import {UploadFileButton} from "../common/UploadFileButton";

const useStyles = theme => {
    return ({
        container: {
            marginBottom: "50px"
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: "10px",
        },
        roundedField: {
            border: '1px solid',
            borderColor: '#68B5E8',
            borderRadius: '30px',
            color: '#68B5E8',
            padding: '10px',
            display: 'inline-block',
            background: 'transparent'
        },
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            marginBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
        csvFileUploadButton: {
            marginRight: "10px",
            marginBottom: "10px"
        },
        uploadImagesContainer: {
            position: "fixed",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2196f3",
            padding: "40px",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            zIndex: 9999,
        }
    })
};

const TenderRequestItemsForm = ({
                                    tenderRequestItemFormData,
                                    saveTenderRequestItem,
                                    updateTenderRequestItemFormData,
                                    uploadTenderRequestItemImages,
                                    downloadTenderRequestItemImages,
                                    clearForm,
                                    classes,
                                    handleCsvUpload,
                                    isUpdatable,
                                    t
                                }) => {

    const chosenImagesRef = createRef();

    const handleChange = event => {
        const {name, value} = event.target;
        updateTenderRequestItemFormData({[name]: value || null});
    };

    const submitFile = () => {
        const filesArray = Array.from(chosenImagesRef.current.files);
        uploadTenderRequestItemImages(tenderRequestItemFormData.id, filesArray);
        chosenImagesRef.current.value = null
    };
    const showDialog = () => {
        chosenImagesRef.current.click();
    };

    const handleSaveTenderRequestItem = e => {
        saveTenderRequestItem();
    };

    const onDrop = (acceptedImages) => {
        if (acceptedImages.length > 0) {
            uploadTenderRequestItemImages(tenderRequestItemFormData.id, acceptedImages);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        multiple: true,
        disabled: !tenderRequestItemFormData.id || tenderRequestItemFormData.image_count > 3,
        noClick: true
    });

    return (
        <div  {...getRootProps()} className={classes.container}>
            <input {...getInputProps()}/>

            <Paper>
                <Grid container spacing={1} className={classes.paper}>
                    <Grid item xs={6}>
                        {tenderRequestItemFormData.id &&
                            <TextField className={classes.textField}
                                       label={t('tender_module:ITEM_ID')}
                                       value={tenderRequestItemFormData.id}
                                       variant="outlined"
                                       margin="dense"
                                       name="request_number"
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       InputLabelProps={{
                                           shrink: true
                                       }}
                            />
                        }
                        <TextField className={classes.textField}
                                   label={'EAN'}
                                   value={tenderRequestItemFormData.ean || ''}
                                   variant="outlined"
                                   margin="dense"
                                   name="ean"
                                   onChange={handleChange}
                                   type="number"
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('ARTICLE_NUMBER')}
                                   value={tenderRequestItemFormData.article_number || ''}
                                   variant="outlined"
                                   margin="dense"
                                   name="article_number"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:MANUFACTURER_NAME')}
                                   value={tenderRequestItemFormData.manufacturer_name || ''}
                                   variant="outlined"
                                   margin="dense"
                                   name="manufacturer_name"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:MODEL_NUMBER')}
                                   value={tenderRequestItemFormData.model_number || ""}
                                   variant="outlined"
                                   margin="dense"
                                   name="model_number"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:QUANTITY_REQUIRED')}
                                   value={tenderRequestItemFormData.quantity || ""}
                                   variant="outlined"
                                   margin="dense"
                                   name="quantity"
                                   onChange={handleChange}
                                   type="number"
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        {tenderRequestItemFormData.id &&
                            <TextField className={classes.textField}
                                       label={t('tender_module:UPLOAD_PHOTO')}
                                       variant="outlined"
                                       InputLabelProps={{
                                           shrink: true,
                                           required: true
                                       }}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <Typography>{tenderRequestItemFormData.image_count} {t('tender_module:PHOTOS_UPLOADED')}</Typography>
                                               </InputAdornment>
                                           ),
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <input
                                                       accept='image/*'
                                                       style={{display: 'none'}}
                                                       type='file'
                                                       multiple
                                                       ref={chosenImagesRef}
                                                       onChange={() => submitFile()}
                                                   />
                                                   <IconButton onClick={() => showDialog()}
                                                               disabled={tenderRequestItemFormData.image_count >= 3}>
                                                       <ImageSearch/>
                                                   </IconButton>
                                                   <IconButton
                                                       onClick={() => {
                                                           downloadTenderRequestItemImages(tenderRequestItemFormData.id)
                                                       }}
                                                       disabled={tenderRequestItemFormData.image_count === 0}>
                                                       <CloudDownload/>
                                                   </IconButton>
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={classes.textField}
                                   select
                                   fullWidth
                                   margin="dense"
                                   label={t('UNIT_OF_MEASURE')}
                                   name="unit_of_measure"
                                   variant="outlined"
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                                   SelectProps={{
                                       displayEmpty: true,
                                       value: tenderRequestItemFormData.unit_of_measure,
                                       onChange: handleChange
                                   }}
                        >
                            {tenderRequestItemUnitsOfMeasure.map((item) => (
                                <MenuItem key={item} value={item}>{t(item)}</MenuItem>
                            ))}
                        </TextField>
                        <TextField className={classes.textField}
                                   label={t('tender_module:DESCRIPTION')}
                                   value={tenderRequestItemFormData.description || ''}
                                   multiline
                                   rows={4}
                                   variant="outlined"
                                   margin="dense"
                                   name="description"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                    </Grid>
                </Grid>
                <Box className={classes.buttonsContainer}>
                    <UploadFileButton onFileUpload={handleCsvUpload} className={classes.csvFileUploadButton}
                                      fileType=".csv" isDisabled={!isUpdatable}
                                      buttonLabel={t("CREATE_ITEMS_FROM_CSV_FILE")}/>
                    {tenderRequestItemFormData.id && (
                        <Button variant="contained" color="primary"
                                style={{marginBottom: "10px", marginRight: "10px"}}
                                onClick={clearForm}>
                            {t('CLEAR')}
                        </Button>
                    )}
                    <Button variant="contained" color="primary" style={{marginBottom: "10px"}}
                            onClick={(e) => handleSaveTenderRequestItem(e)}>
                        {t(tenderRequestItemFormData.id ? 'UPDATE_ITEM' : 'ADD_ITEM')}
                    </Button>
                </Box>

                {isDragActive && (
                    <Box className={classes.uploadImagesContainer}>
                        <CloudUpload fontSize="large"/>
                        <Typography>Drop your file here to upload</Typography>
                    </Box>
                )}

            </Paper>
        </div>
    )
}

export default withStyles(useStyles)(TenderRequestItemsForm)