import React from "react";

import {FormControl, Input, MenuItem, Select} from '@material-ui/core';

export default function SelectLicense(props) {
    const {value, onChange, licenses} = props;
    const [selectedValue, setSelectedValue] = React.useState(value);

    const handleChange = value => {
        setSelectedValue(value.target.value);
        onChange(value.target.value);
    };

    return (
        <FormControl>
            <Select
                value={selectedValue}
                onChange={handleChange}
                input={<Input/>}
            >
                {licenses.map(element => (
                    <MenuItem key={element.id} value={element.name} name="element.name">
                        {element.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}