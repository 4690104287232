import React, { useRef } from 'react';
import { Button } from '@material-ui/core';

export const UploadFileButton = ({ onFileUpload, className, fileType, isDisabled, buttonLabel }) => {

    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            onFileUpload(selectedFile);
            fileInputRef.current.value = null
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                accept={fileType}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={()=>handleButtonClick()}
                className={className}
                disabled={isDisabled}
            >
                {buttonLabel}
            </Button>
        </>
    );
};