import {combineReducers} from "redux";
import esearch from './esearch';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import users from './users';
import loading from './loading';
import orders from './orders';
import websocket from './websocket';
import tenderRequest from './tenderRequest';
import supplier from './supplier';

export default combineReducers({
    esearchReducer: esearch,
    errorsReducer: errors,
    messagesReducer: messages,
    authReducer: auth,
    usersReducer: users,
    loadingReducer: loading,
    ordersReducer: orders,
    websocketReducer: websocket,
    tenderRequestReducer: tenderRequest,
    supplierReducer: supplier
})