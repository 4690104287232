import React, { Fragment } from "react";
import CompanyRole from './CompanyRole';
import UserRole from './UserRole';

export default function Dashboard() {
    return (
        <Fragment>
            <CompanyRole/>
            <UserRole/>
        </Fragment>
    );
}