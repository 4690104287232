import React, {Component} from 'react';
import {Redirect, Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {resetPassword} from "../../actions/auth";
import {returnErrors} from "../../actions/messages";

import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from 'react-i18next';
import {TextField, Button, Typography, Container, Avatar, Link} from "@material-ui/core";
import {LockOutlined} from '@material-ui/icons';


const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    })
};

class ResetPassword extends Component {
    static propTypes = {
        resetPassword: PropTypes.func.isRequired,
        returnErrors: PropTypes.func.isRequired,
        resetPasswordState: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool
    };

    state = {
        password: '',
        password2: ''
    };

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    };

    onSubmit = event => {
        event.preventDefault();
        const {password, password2} = this.state;
        const {token} = this.props.match.params;
        if (password !== password2) {
            this.props.returnErrors({passwords: ' do not match'})
        } else {
            this.props.resetPassword({token, password});
            this.setState({
                password: '',
                password2: ''
            });
        }
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/"/>
        }
        const {password, password2} = this.state;
        const {classes ,t} = this.props;

        if (this.props.resetPasswordState) {
            return <Redirect to="/login"/>
        }

        return (
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('fillInNewPassword')}
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                        <TextField
                            onChange={this.onChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label={t('passWord')}
                            name="password"
                            value={password}
                            autoFocus
                            autoComplete="new-password"
                            type="password"
                        />
                        <TextField
                            onChange={this.onChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            value={password2}
                            name="password2"
                            label={t('repeatPassword')}
                            type="password2"
                            id="password2"
                            autoComplete="new-password"
                            type="password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('resetPassword')}
                        </Button>
                    </form>
                </div>
            </Container>

        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    resetPasswordState: state.authReducer.resetPasswordState,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    resetPassword,
    returnErrors
})(withTranslation('login')(ResetPassword)));