import {connect} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Box, Button, Container} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import AdminOrderForm from "./AdminOrderForm";
import AdminOrderItemsTable from "./AdminOrderItemsTable";
import AdminOrderItemsForm from "./AdminOrderItemForm";
import {withStyles} from "@material-ui/core/styles";
import {getAllSuppliers2ba} from "../../../actions/supplier";
import {getAllCompanies, getAllBranchOffices, getAllCostCenters} from "../../../actions/esearch";
import {
    createAdminOrder,
    updateAdminOrder,
    getAdminOrderById,
    clearAdminOrder,
    createAdminOrderItem,
    updateAdminOrderItem,
    deleteAdminOrderItem,
} from "../../../actions/orders";
import {emptyAdminOrderItem} from "../../../reducers/orders";
import {CompanyRoleEnum} from "../../../utils/constants";


const useStyles = () => {
    return ({
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: "10px 10px 10px 0"
        },
    })
};

const AdminOrder = ({
                        currentAdminOrder,
                        currentAdminOrderItem,
                        getAllCompanies,
                        companies,
                        user,
                        getAllSuppliers2ba,
                        suppliers,
                        createAdminOrder,
                        updateAdminOrder,
                        getAdminOrderById,
                        clearAdminOrder,
                        createAdminOrderItem,
                        updateAdminOrderItem,
                        deleteAdminOrderItem,
                        getAllBranchOffices,
                        getAllCostCenters,
                        costCenters,
                        branchOffices,
                        t,
                        classes,
                    }) => {
    const {id} = useParams();
    const history = useHistory();
    const [displayAdminOrderForm, setDisplayAdminOrderForm] = useState(true)
    const [orderFormData, setOrderFormData] = useState(currentAdminOrder)
    const [orderItemFormData, setOrderItemFormData] = useState(currentAdminOrderItem)


    useEffect(() => {
        getAllSuppliers2ba();
        getAllCompanies(CompanyRoleEnum.CLIENT);
        if (id) {
            getAdminOrderById(id)
        } else {
            clearAdminOrder()
        }
    }, [id]);

    useEffect(() => {
        if (orderFormData.company) {
            getAllCostCenters(orderFormData.company);
            getAllBranchOffices(orderFormData.company);
        }
    }, [orderFormData.company]);

    useEffect(() => {
            setOrderFormData(currentAdminOrder)
    }, [currentAdminOrder])

    useEffect(() => {
        setOrderItemFormData(currentAdminOrderItem)
    }, [currentAdminOrderItem])

    const updateOrderFormData = (newData) => {
        setOrderFormData({
            ...orderFormData,
            ...newData
        })
    }

    const updateOrderItemFormData = (newData) => {
        setOrderItemFormData({
            ...orderItemFormData,
            ...newData
        })
    }

    const handleSaveOrder = () => {
        if (!orderFormData.id) {
            createAdminOrder(orderFormData)
        } else {
            updateAdminOrder(orderFormData)
        }
    }

    const handleSaveOrderItem = () => {
        const payload = {...orderItemFormData, order: currentAdminOrder.id}
        if (!orderItemFormData.id) {
            createAdminOrderItem(payload)
        } else {
            updateAdminOrderItem(payload)
        }
    }

    const selectOrderItem = (orderItem) => {
        setOrderItemFormData(orderItem)
        setDisplayAdminOrderForm(false)
    }

    const handleDeleteOrderItem = (orderItemId) => {
        deleteAdminOrderItem(currentAdminOrder.id, orderItemId);
        if (orderItemFormData.id === orderItemId) {
            setOrderItemFormData(emptyAdminOrderItem)
        }
    }

    return (
        <Container maxWidth={false}>
            {displayAdminOrderForm ? (
                    <AdminOrderForm
                        t={t}
                        user={user}
                        companies={companies}
                        updateOrderFormData={updateOrderFormData}
                        saveOrder={handleSaveOrder}
                        clearForm={() => history.push('/admin-order')}
                        orderFormData={orderFormData}
                        costCenters={costCenters}
                        branchOffices={branchOffices}
                    />
                )
                :
                (
                    <AdminOrderItemsForm
                        t={t}
                        orderItemFormData={orderItemFormData}
                        updateOrderItemFormData={updateOrderItemFormData}
                        saveOrderItem={handleSaveOrderItem}
                        clearForm={() => setOrderItemFormData(emptyAdminOrderItem)}
                        getAllCompanies={getAllCompanies}
                        suppliers={suppliers}
                    />
                )
            }

            <Box className={classes.buttonContainer}>
                {displayAdminOrderForm ? (
                        <Button variant="contained"
                                color="primary"
                                onClick={() => setDisplayAdminOrderForm(false)}
                                disabled={!id}
                        >
                            {t('ADD_ORDER_ITEMS')}
                        </Button>
                    )
                    :
                    (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setDisplayAdminOrderForm(true)}
                        >
                            {t('RETURN_TO_ADMIN_ORDER')}
                        </Button>
                    )
                }
            </Box>

            <AdminOrderItemsTable t={t} items={id ? currentAdminOrder.items : []} user={user}
                                  selectOrderItem={selectOrderItem} deleteAdminOrderItem={handleDeleteOrderItem}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    currentAdminOrder: state.ordersReducer.currentAdminOrder,
    currentAdminOrderItem: state.ordersReducer.currentAdminOrderItem,
    user: state.authReducer.user,
    suppliers: state.supplierReducer.suppliers2ba.items,
    companies: state.esearchReducer.companies.items,
    costCenters: state.esearchReducer.costCenters,
    branchOffices: state.esearchReducer.branchOffices
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getAllSuppliers2ba,
    getAllCompanies,
    createAdminOrder,
    updateAdminOrder,
    getAdminOrderById,
    clearAdminOrder,
    createAdminOrderItem,
    updateAdminOrderItem,
    deleteAdminOrderItem,
    getAllBranchOffices,
    getAllCostCenters
})(withTranslation(['common'])(AdminOrder)));