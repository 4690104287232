import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, Container, Grid, MenuItem, Paper, TextField} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';
import { incoterms } from '../../utils/constants';
import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    GetApp,
    Send,
    Save,
    Close,
    FileCopy
} from "@material-ui/icons";
import {
    addToBasket,
    removeFromBasket,
    editBasket,
    updateOrder,
    downloadXML,
    sendXML,
    closeOrder,
    copyOrder,
    processEciOrder,
    getOrder
} from "../../actions/orders";
import {logoutUser} from '../../actions/auth';
import deepCopy from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {OrderQuantity} from "./OrderQuantity";
import {validateQuantityInput, OrderStatus, formatPrice} from "../../utils/orderUtils";
import {showError} from "../../actions/messages";
import {isAdmin} from "../../utils/roleUtils";
import {supportedCurrencySymbols} from "../../utils/constants";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            marginBottom: theme.spacing(2),
            flexGrow: 1
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        },
        displayNone: {
            display: 'none'
        },
        deepLink: {
            display: 'table-cell',
            textDecoration: 'none',
            color: 'inherit'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
        formControl: {
            margin: theme.spacing(1),
        },
    })
};

const priceAmountThreshold = 0.1;

class ShoppingCart extends Component {

    static propTypes = {
        current_order: PropTypes.object,
        user: PropTypes.object.isRequired,
        addToBasket: PropTypes.func.isRequired,
        removeFromBasket: PropTypes.func.isRequired,
        editBasket: PropTypes.func.isRequired,
        updateOrder: PropTypes.func.isRequired,
        downloadXML: PropTypes.func.isRequired,
        sendXML: PropTypes.func.isRequired,
        closeOrder: PropTypes.func.isRequired,
        copyOrder: PropTypes.func.isRequired,
        logoutUser: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
        processEciOrder: PropTypes.func.isRequired,
        getOrder: PropTypes.func.isRequired,
    };

    state = {
        id: this.props.current_order.id,
        cost_center: this.props.current_order.cost_center,
        delivery_location: this.props.current_order.delivery_location,
        accounting_location: this.props.current_order.accounting_location,
        buyers_location: this.props.current_order.buyers_location,
        project_reference: this.props.current_order.project_reference,
        customer_reference: this.props.current_order.customer_reference,
        note: this.props.current_order.note,
        department: this.props.current_order.department,
        incoterm: this.props.current_order.incoterm,
        delivery_date: this.props.current_order.delivery_date,
        order_edited: false,
    };


    componentDidMount() {
        const {id} = this.props.match.params;
        this.props.getOrder(id)
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.order_edited === true) {
            return null;
        }
        if (
            nextProps.current_order.department !== state.department ||
            nextProps.current_order.cost_center !== state.cost_center ||
            nextProps.current_order.delivery_location !== state.delivery_location ||
            nextProps.current_order.accounting_location !== state.accounting_location ||
            nextProps.current_order.buyers_location !== state.buyers_location ||
            nextProps.current_order.project_reference !== state.project_reference ||
            nextProps.current_order.customer_reference !== state.customer_reference ||
            nextProps.current_order.note !== state.note ||
            nextProps.current_order.incoterm !== state.incoterm ||
            nextProps.current_order.id !== state.id ||
            nextProps.current_order.delivery_date !== state.delivery_date
        ) {
            return {
                id: nextProps.current_order.id,
                cost_center: nextProps.current_order.cost_center,
                delivery_location: nextProps.current_order.delivery_location,
                accounting_location: nextProps.current_order.accounting_location,
                buyers_location: nextProps.current_order.buyers_location,
                project_reference: nextProps.current_order.project_reference,
                customer_reference: nextProps.current_order.customer_reference,
                note: nextProps.current_order.note,
                department: nextProps.current_order.department,
                incoterm: nextProps.current_order.incoterm,
                delivery_date: nextProps.current_order.delivery_date,
            };
        }
        return null;
    }

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
        this.setState({order_edited: true})
    };

    onDateChange = (event) => {
        this.setState({delivery_date: event.toISOString().split('T')[0], order_edited: true})
    };

    onSubmit = event => {
        event.preventDefault();
        const {
            cost_center, delivery_location, accounting_location, buyers_location, project_reference, customer_reference,
            note, department, incoterm, id, delivery_date, status
        } = this.state;

        const data = {
            cost_center, delivery_location, accounting_location, buyers_location, project_reference, customer_reference,
            note, department, incoterm, id, delivery_date, status
        };
        this.props.updateOrder(data);
        this.state.order_edited = false;
    };

    check_user_level = () => {
        if (this.props.user.user_role === 'Demo') {
            this.props.logoutUser();
            window.location.reload();
            window.location.href = 'https://www.esearch.nu/';
            return null;
        }
    }

    downloadXML = event => {
        event.preventDefault();
        if (this.check_user_level() !== null) {
            this.props.downloadXML(this.props.current_order.id, 'download')
        }
    };

    sendXML = (event, t) => {
        if (!this.state.customer_reference) {
            this.props.showError(t('CUSTOMER_REFERENCE_REQUIRED_ERROR'))
            return
        }

        event.preventDefault();
        if (this.check_user_level() !== null) {
            this.props.sendXML(this.props.current_order.id)
            this.props.history.push('/orders')
        }
    };

    renderConfirmedValue = (confirmedValue, originalValue) => {
        if (!confirmedValue) {
            return ""
        }

        let style = {}
        if (confirmedValue !== originalValue) {
            style.color = confirmedValue < originalValue ? 'green' : 'red';
        }

        return (
            <span style={style}>
                {confirmedValue}
            </span>
        );
    };


    renderValueWithThreshold = (confirmedValue, originalValue, currencyCode) => {
        if (!confirmedValue && confirmedValue !== 0) {
            return ""
        }

        let style = {}
        if (confirmedValue < originalValue) {
            style.color = 'green';
        } else {
            const difference = Math.abs(confirmedValue - originalValue);
            const threshold = originalValue * priceAmountThreshold / 100;

            if (difference > threshold) {
                style.color = 'red'
            }
        }

        return (
            <span style={style}>
                {formatPrice(currencyCode, confirmedValue)}
            </span>
        );
    };

    render() {
        let {
            cost_center, delivery_location, accounting_location, buyers_location, project_reference, customer_reference,
            note, department, incoterm, delivery_date
        } = this.state;
        const {classes, t} = this.props;
        const currencySymbol = supportedCurrencySymbols.EUR

        const basketTotal = this.props.current_order.basket_total_with_handling_fee
        let tableTitle = ``;

        const supplierCosts = this.props.current_order.supplier_costs
        if (supplierCosts) {
            if (supplierCosts.transport_costs) {
                tableTitle += `, ${t('TRANSPORT_COSTS')}: ${currencySymbol} ${supplierCosts.transport_costs} `;
            }
            if (supplierCosts.order_costs) {
                tableTitle += `, ${t('ORDER_COSTS')}: ${currencySymbol} ${supplierCosts.order_costs} `;
            }
        }

        const orderTotal = this.props.current_order.order_total
        if (basketTotal !== orderTotal) {
            tableTitle = `${t('AMOUNT')}: ${currencySymbol} ${basketTotal}` + tableTitle + `, ${t('TOTAL')}: ${currencySymbol} ${orderTotal}`
        } else {
            tableTitle += `${t('TOTAL')}: ${currencySymbol} ${basketTotal}`;
        }

        const isEditable = this.props.current_order.is_editable
        const getBranchOfficeSelectValue = (location) => {
            if (!location) {
                return ''
            }

            return !isEditable ? location.branch_name :
                location ? this.props.user.branch_offices[this.props.user.branch_offices.findIndex(i => i.branch_name === location.branch_name)] : ''
        }

        const getCostCenterSelectValue = (costCenter) => {
            if (!costCenter) {
                return ''
            }

            return !isEditable ? costCenter.name :
                location ? this.props.user.cost_centers[this.props.user.cost_centers.findIndex(i => i.name === costCenter.name)] : ''
        }


        const columns = [
            {title: t('ID'), field: 'order_item_id', editable: 'never', defaultSort: 'asc'},
            {
                title: t('EAN_NUMBER'), field: 'GTIN', editable: 'never', render: rowData => (
                    <a style={{color: '#0390fc'}} href={`/?query=${encodeURIComponent(rowData.GTIN)}`}
                       target="_blank">{rowData.GTIN}</a>
                )
            },
            {title: t('ARTICLE_NUMBER'), field: 'TradeItemId', editable: 'never'},
            {title: t('SUPPLIER_NAME'), field: 'SupplierName', editable: 'never'},
            {title: t('PRODUCT_DESCRIPTION'), field: 'Description', editable: 'never'},
            {title: t('STATUS'), field: 'status', editable: 'never'},
            {title: t('UNIT_OF_MEASURE'), field: 'OrderUnitMeasureUnitCode', editable: 'never'},
            {title: t('PACKAGE_CONTENT'), field: 'MinimumOrderQuantity', editable: 'never'},
            {
                title: t('QUANTITY'), field: 'OrderQuantity', editable: 'onUpdate', type: 'numeric',
                editComponent: ({rowData, onChange}) =>
                    <OrderQuantity minOrderQuantity={rowData.MinimumOrderQuantity} t={t} onChange={onChange}
                                   orderQuantity={rowData.OrderQuantity}/>
            }
        ]

        columns.push(
            {
                title: t('PRICE_PER_PACKAGE'),
                editable: 'never',
                render: rowData => {
                    const netPrice = rowData.NetPriceWithHandlingFee || rowData.NetPriceInOrderUnit
                    return formatPrice(rowData.CurrencyCode, netPrice)
                }
            },
        )

        if (this.props.current_order.status && !isEditable) {
            columns.push(
                {
                    title: t('CONFIRMED_PRICE_PER_PACKAGE'),
                    field: 'confirmed_net_price',
                    editable: 'never',
                    type: 'numeric',
                    render: (rowData) => {
                        const confirmedNetPrice = rowData.confirmed_net_price_with_handling_fee || rowData.confirmed_net_price
                        const netPrice = rowData.NetPriceWithHandlingFee || rowData.NetPriceInOrderUnit
                        return this.renderValueWithThreshold(confirmedNetPrice, netPrice, rowData.CurrencyCode)
                    },
                },
            )
        }

        columns.push(
            {
                title: t('AMOUNT'),
                field: '',
                editable: 'never',
                render: rowData => {
                    const netPrice = rowData.NetPriceWithHandlingFee || rowData.NetPriceInOrderUnit
                    return formatPrice(rowData.CurrencyCode, rowData.OrderQuantity * netPrice)
                }
            }
        )
        if (this.props.current_order.status && !isEditable) {
            columns.push(
                {
                    title: t('CONFIRMED_AMOUNT'),
                    field: 'confirmed_amount',
                    editable: 'never',
                    type: 'numeric',
                    render: (rowData) => {
                        const confirmedTotal = rowData.confirmed_total_with_handling_fee || rowData.confirmed_total
                        const netPrice = rowData.NetPriceWithHandlingFee || rowData.NetPriceInOrderUnit
                        return this.renderValueWithThreshold(parseFloat(confirmedTotal),
                            parseFloat((rowData.OrderQuantity * netPrice).toFixed(2)), rowData.CurrencyCode)
                    },
                },
                {title: t('DELIVERY_DATE'), field: 'delivery_end_date', editable: 'never'},
                {
                    title: t('CONFIRMED_DELIVERY_DATE'),
                    field: 'confirmed_delivery_date',
                    editable: 'never',
                    render: (rowData) => this.renderConfirmedValue(rowData.confirmed_delivery_date,
                        rowData.delivery_end_date),
                }
            )
        }

        return (
            <Container maxWidth={false}>
                {isAdmin(this.props.user) && this.props.current_order.status === OrderStatus.INTEGRATION_FAILED &&
                    <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                        <Button variant="contained" color="primary"
                                onClick={() => this.props.processEciOrder(this.props.current_order.id)}>
                            {t('PROCESS_ECI_ORDER')}
                        </Button>
                    </Box>
                }

                <form onSubmit={this.onSubmit}>
                    <Paper>
                        <Grid container spacing={1} className={classes.paper}>
                            <Grid item xs={6}>
                                <TextField className={classes.textField}
                                           label={t('ORDER_NUMBER')}
                                           value={this.props.current_order.id}
                                           variant="outlined"
                                           margin="dense"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('FULL_NAME')}
                                           value={this.props.current_order.full_name}
                                           variant="outlined"
                                           margin="dense"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('NOTE')}
                                           value={note}
                                           variant="outlined"
                                           margin="dense"
                                           name="note"
                                           onChange={this.onChange}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('STATUS')}
                                           value={this.props.current_order.status}
                                           variant="outlined"
                                           margin="dense"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('CUSTOMER_REFERENCE')}
                                           value={customer_reference}
                                           variant="outlined"
                                           margin="dense"
                                           name="customer_reference"
                                           onChange={this.onChange}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                />
                                <TextField className={classes.textField}
                                           select={isEditable}
                                           fullWidth
                                           margin="dense"
                                           label={t('DELIVERY_LOCATION')}
                                           value={getBranchOfficeSelectValue(delivery_location)}
                                           name="delivery_location"
                                           onChange={this.onChange}
                                           variant="outlined"
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                           SelectProps={{
                                               displayEmpty: true,
                                               renderValue: (data) =>
                                                   <span>{data ? data.branch_name : t('NOT_DEFINED')}</span>
                                           }}
                                >

                                    {isEditable && this.props.user.branch_offices.map((office, indx) => (
                                        <MenuItem key={office.id} value={this.props.user.branch_offices[indx]}
                                                  name="delivery_location">
                                            {office.branch_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={classes.textField}
                                                        autoOk
                                                        disableToolbar
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        format="yyyy/MM/dd"
                                                        margin="dense"
                                                        name="delivery_date"
                                                        label={t('DELIVERY_DATE')}
                                                        value={delivery_date}
                                                        onChange={date => this.onDateChange(date)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            readOnly: !isEditable,
                                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField className={classes.textField}
                                           label={t('CREATED_DATE')}
                                           value={this.props.current_order.order_created}
                                           variant="outlined"
                                           margin="dense"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('DEPARTMENT')}
                                           value={department}
                                           variant="outlined"
                                           margin="dense"
                                           name="department"
                                           onChange={this.onChange}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                />
                                <TextField className={classes.textField}
                                           select={isEditable}
                                           fullWidth
                                           margin="dense"
                                           label="Incoterm"
                                           value={incoterm ? incoterm : ''}
                                           name="incoterm"
                                           onChange={this.onChange}
                                           variant="outlined"
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                           SelectProps={{
                                               displayEmpty: true,
                                               renderValue: (incoterm) =>
                                                   <span>{incoterm ? incoterm : t('NOT_DEFINED')}</span>
                                           }}
                                >
                                    {incoterms.map(term => (
                                        <MenuItem key={term} value={term} name="term">
                                            {term}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField className={classes.textField}
                                           select={isEditable}
                                           fullWidth
                                           margin="dense"
                                           label={t('COST_CENTER')}
                                           value={getCostCenterSelectValue(cost_center)}
                                           name="cost_center"
                                           onChange={this.onChange}
                                           variant="outlined"
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                           SelectProps={{
                                               displayEmpty: true,
                                               renderValue: (data) =>
                                                   <span>{data ? data.name : t('NOT_DEFINED')}</span>
                                           }}
                                >
                                    {isEditable && this.props.user.cost_centers.map((center, indx) => (
                                        <MenuItem key={center.id} value={this.props.user.cost_centers[indx]}
                                                  name="center.name">
                                            {center.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField className={classes.textField}
                                           select={isEditable}
                                           fullWidth
                                           margin="dense"
                                           label={t('ACCOUNTING_OFFICE')}
                                           value={getBranchOfficeSelectValue(accounting_location)}
                                           name="accounting_location"
                                           onChange={this.onChange}
                                           variant="outlined"
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                           SelectProps={{
                                               displayEmpty: true,
                                               renderValue: (data) =>
                                                   <span>{data ? data.branch_name : t('NOT_DEFINED')}</span>
                                           }}
                                >
                                    {isEditable && this.props.user.accounting_office.map((office, indx) => (
                                        <MenuItem key={office.id} value={this.props.user.accounting_office[indx]}
                                                  name="office.branch_name">
                                            {office.branch_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField className={classes.textField}
                                           select={isEditable}
                                           fullWidth
                                           margin="dense"
                                           label={t('BUYERS_LOCATION')}
                                           value={getBranchOfficeSelectValue(buyers_location)}
                                           name="buyers_location"
                                           onChange={this.onChange}
                                           variant="outlined"
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                           InputProps={{
                                               readOnly: !isEditable,
                                           }}
                                           SelectProps={{
                                               displayEmpty: true,
                                               renderValue: (data) =>
                                                   <span>{data ? data.branch_name : t('NOT_DEFINED')}</span>
                                           }}
                                >
                                    {isEditable && this.props.user.branch_offices.map((office, indx) => (
                                        <MenuItem key={office.id} value={this.props.user.branch_offices[indx]}
                                                  name="office.branch_name">
                                            {office.branch_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField className={classes.textField}
                                           label={t('SENT_DATE')}
                                           value={this.props.current_order.sent_date}
                                           variant="outlined"
                                           margin="dense"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                            </Grid>

                            {(this.props.current_order.status === OrderStatus.SEND_FAILED ||
                                    this.props.current_order.status === OrderStatus.INTEGRATION_FAILED) &&
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        className={classes.textField}
                                        label={t('ORDER_INTEGRATION_ERROR')}
                                        value={this.props.current_order.integration_error_message}
                                        variant="outlined"
                                        margin="dense"
                                        name="newField"
                                        onChange={this.onChange}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Paper>
                </form>
                <MaterialTable
                    icons={tableIcons}
                    title={tableTitle}
                    columns={columns}
                    data={this.props.current_order.basket}
                    options={{
                        search: false,
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        }
                    }}
                    localization={localization(t)}
                    editable={{
                        isDeletable: () => {
                            return isEditable
                        },
                        isEditable: () => {
                            return isEditable
                        },
                        onRowDelete: oldData => new Promise((resolve, reject) => {
                            this.props.removeFromBasket(oldData, this.props.current_order.basket, this.props.current_order.id);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            if (!validateQuantityInput(newData.OrderQuantity, newData.MinimumOrderQuantity)) {
                                reject()
                                return
                            }
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editBasket(newData, oldData, this.props.current_order.basket, this.props.current_order.id);
                            }
                            resolve();
                        }),
                    }}
                    actions={[
                        {
                            icon: Close,
                            tooltip: t('CLOSE_ORDER'),
                            isFreeAction: true,
                            onClick: () => this.props.closeOrder(),
                        },
                        {
                            icon: FileCopy,
                            tooltip: t('COPY_ORDER'),
                            isFreeAction: true,
                            onClick: () => this.props.copyOrder(this.props.current_order.basket, this.props.user),
                        },
                        {
                            icon: Save,
                            tooltip: t('SAVE_ORDER'),
                            isFreeAction: true,
                            onClick: (event) => this.onSubmit(event),
                            disabled: !this.state.order_edited || !isEditable
                        },
                        {
                            icon: GetApp,
                            tooltip: t('DOWNLOAD_ORDER'),
                            isFreeAction: true,
                            onClick: (event) => this.downloadXML(event),
                            disabled: this.props.current_order.basket.length === 0
                        },
                        {
                            icon: Send,
                            tooltip: t('EMAIL_ORDER'),
                            isFreeAction: true,
                            onClick: (event) => this.sendXML(event, t),
                            disabled: this.props.current_order.basket.length === 0 || !isEditable
                        }
                    ]}
                />
            </Container>
        )

    }
}

const mapStateToProps = state => ({
    current_order: state.ordersReducer.current_order,
    user: state.authReducer.user
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    addToBasket,
    removeFromBasket,
    editBasket,
    updateOrder,
    downloadXML,
    sendXML,
    closeOrder,
    copyOrder,
    logoutUser,
    showError,
    processEciOrder,
    getOrder
})(withTranslation(['common'])(ShoppingCart)));
