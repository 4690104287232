import {
    AUTH_ERROR,
    GET_USER_ENGINES,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    SAVE_USER_SETTINGS,
    SET_THEME,
    UPDATE_USER_ENGINE,
    UPDATE_USER_SEARCH_HISTORY,
    USER_LOADED,
    USER_LOADING
} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    expiry: localStorage.getItem('expiry'),
    isAuthenticated: null,
    isLoading: false,
    isStaff: false,
    user: null,
    userSearchEngines: null,
    resetPasswordState: false,
    ln: localStorage.getItem('i18nextLng') || 'en',
    fallbackImage: '/static/images/default_image.png'
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isStaff: action.payload.is_staff,
                isLoading: false,
                user: action.payload
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('expiry', action.payload.expiry);
            return {
                ...state,
                isAuthenticated: true,
                isStaff: action.payload.user.is_staff,
                isLoading: false,
                ...action.payload,
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            localStorage.removeItem('expiry');
            return {
                ...state,
                token: null,
                expiry: null,
                user: null,
                isAuthenticated: false,
                isStaff: false,
                isLoading: false
            };
        case GET_USER_ENGINES:
            return {
                ...state,
                userSearchEngines: action.payload.results
            };
        case UPDATE_USER_ENGINE:
            return {
                ...state,
                userSearchEngines: state.userSearchEngines.filter(use => use !== action.oldData).concat(action.newData).sort((a, b) => (a.search_engine > b.search_engine) ? 1 : -1)
            };
        case RESET_PASSWORD_ERROR:
        case RESET_PASSWORD_INIT:
            return {
                ...state,
                resetPasswordState: false
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordState: true
            };
        case SET_THEME:
            return {
                ...state,
                fallbackImage: action.payload ? '/static/images/default_image.png' : '/static/images/default_image_light.png'
            };
        case SAVE_USER_SETTINGS:
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: action.payload
                }
            };
        case UPDATE_USER_SEARCH_HISTORY:
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...state.user.settings,
                        search_history: action.payload
                    }
                }
            };
        default:
            return state;
    }
}