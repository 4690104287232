import React, {Component, Fragment} from 'react';
import {withAlert} from 'react-alert';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class Alerts extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        message: PropTypes.object.isRequired
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { error, alert, message, t } = this.props;
        if (error !== prevProps.error) {
            alert.error(Object.entries(error.message).join());
        }
        if (message !== prevProps.message) {
            if (message.hasOwnProperty('count')){
                alert.success(message.count + ` ${t(Object.values(message))}`)
            } else {
                alert.success(t(Object.values(message)))
            }

        }
    }

    render() {
        return <Fragment/>;
    }
}

const mapStateToProps = state => ({
    error: state.errorsReducer,
    message: state.messagesReducer
});


export default withTranslation(['messages'])(connect(mapStateToProps)(withAlert()(Alerts)));