import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core/styles";
import {Container, Button, Box} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';
import {getCompanyOrders, deleteOrder} from "../../actions/orders";
import OrderIntegrationFormModal from "./OrderIntegrationFormModal";

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import {isAdmin} from "../../utils/roleUtils";
import {withRouter} from 'react-router-dom';
import {calculatePage} from "../../utils/tableUtils";
import {OrderStatus} from "../../utils/orderUtils";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        },
        displayNone: {
            display: 'none'
        },
        deepLink: {
            display: 'table-cell',
            textDecoration: 'none',
            color: 'inherit'
        },
        modalPaper: {
            width: '50%',
            padding: theme.spacing(2),
        }
    })
};


class Orders extends Component {

    static propTypes = {
        company_orders: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        getCompanyOrders: PropTypes.func.isRequired,
        deleteOrder: PropTypes.func.isRequired
    };

    state = {
        page: 0,
        pageSize: 20,
        isXmlFormModalOpen: false,
    }

    handlePageChange = (newPage) => {
        this.setState({page: newPage}, () => {
            this.fetchOrders();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({pageSize: newPageSize, page: 0}, () => {
            this.fetchOrders();
        });
    }

    handleSearchChange = (searchQuery) => {
        this.setState({searchQuery, page: 0}, () => {
            this.fetchOrders();
        });
    }

    fetchOrders = () => {
        const {page, pageSize, searchQuery} = this.state;
        this.props.getCompanyOrders(page + 1, pageSize, searchQuery);
    }

    handleRowDelete = (orderId) => {
        this.props.deleteOrder(orderId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.company_orders.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchOrders()
    }


    openXmlFormModal = () => {
        this.setState({isXmlFormModalOpen: true});
    };

    closeXmlFormModal = () => {
        this.setState({isXmlFormModalOpen: false});
    };

    render() {
        const {classes, t} = this.props;
        const table_title = this.props.user.parent_company.name;

        return (
            <Container maxWidth={false}>

                {isAdmin(this.props.user) &&
                    <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                        <Button variant="contained" color="primary" onClick={() => this.openXmlFormModal()}>
                            {t('INTEGRATE_ORDER')}
                        </Button>
                    </Box>
                }

                <OrderIntegrationFormModal
                    isOpen={this.state.isXmlFormModalOpen}
                    closeModal={this.closeXmlFormModal}
                />


                <MaterialTable
                    icons={tableIcons}
                    title={table_title}
                    columns={[
                        {title: t('ORDER_NUMBER'), field: 'id', editable: 'never'},
                        {title: t('CREATED_DATE'), field: 'order_created', editable: 'never', type: 'date'},
                        {title: t('FULL_NAME'), field: 'full_name', editable: 'never'},
                        {title: t('DEPARTMENT'), field: 'department', editable: 'never'},
                        {title: t('NOTE'), field: 'note', editable: 'never'},
                        {title: t('STATUS'), field: 'status', editable: 'never'},
                        {title: t('CUSTOMER_REFERENCE'), field: 'customer_reference', editable: 'never'},
                        {title: t('TOTAL'), field: 'order_total', editable: 'never'}
                    ]}
                    data={this.props.company_orders.items}
                    page={this.state.page}
                    totalCount={this.props.company_orders.totalCount}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handlePageSizeChange}
                    onSearchChange={this.handleSearchChange}
                    options={{
                        search: true,
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: this.state.pageSize,
                    }}
                    localization={localization(t)}
                    onRowClick={((event, rowData) => {
                            event.preventDefault();
                            this.props.history.push(`/cart/${rowData.id}`)
                        }
                    )}
                    editable={{
                        isDeletable: rowData => {
                            return rowData.is_editable
                        },
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id);
                            resolve();
                        })
                    }}
                    actions={this.props.user.is_esearch_admin ? [
                        rowData=>({
                            icon: tableIcons.Edit,
                            disabled: ![OrderStatus.CREATED, OrderStatus.SEND_FAILED].includes(rowData.status),
                            tooltip: t('table.body.editTooltip'),
                            onClick: (event, rowData) => {
                                event.stopPropagation()
                                this.props.history.push(`/admin-order/${rowData.id}`)
                            }
                        })
                    ] : []}
                />
            </Container>
        )

    }
}

const mapStateToProps = state => ({
    company_orders: state.ordersReducer.company_orders,
    user: state.authReducer.user
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getCompanyOrders,
    deleteOrder
})(withTranslation(['common'])(withRouter(Orders))));